import { FormInputValue } from '..';
import {
  FormInputValidationResult,
  FormInputValidationRules,
} from '../interfaces';
import {
  loqateValidateEmail,
  customersFindCustomerByEmailQuery,
  initializeApollo,
} from '../../server';

export const emailValidator = async (
  value: FormInputValue,
  props?: FormInputValidationRules
): Promise<FormInputValidationResult> => {
  if (typeof value !== 'string') {
    return { valid: false, errors: ['Invalid email format'] };
  }

  if ((value as any).length) {
    if (!props?.skipEmailFormat) {
      if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        return { valid: false, errors: ['Invalid email format'] };
      }
    }

    if (!props?.skipEmailLoqate) {
      const loqateResponse = await loqateValidateEmail(
        encodeURIComponent(value)
      );

      if (
        loqateResponse.response?.ResponseCode !== 'Valid' &&
        loqateResponse.response?.ResponseCode !== 'Valid_CatchAll'
      ) {
        return loqateResponse.response?.Domain === 'localhost'
          ? { valid: false, errors: ['Invalid email format'] }
          : { valid: false, errors: ['Undeliverable email address'] };
      }
    }

    if (!props?.skipEmailUnique) {
      const apolloClient = initializeApollo(null);

      const customerEmailQuery = await customersFindCustomerByEmailQuery(
        apolloClient,
        value
      );

      if (customerEmailQuery.data.findCustomerByEmail === true) {
        return { valid: false, errors: ['Already registered'] };
      }
    }
  }

  return { valid: true, errors: [] };
};
