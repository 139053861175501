import { initializeApollo } from '../../server';
import { Dispatch } from 'react';
import { orderListSlice } from '../redux/orders/order';
import { appStorage, AppStorageKey } from '../../helpers';
import {
  customersAuthedCustomerQuery,
  ordersListCustomerOrdersQuery,
} from '../../server';
import {
  customerSlice,
  CustomerDataState,
  cleanCustomerDataState,
  modernVersionBridge,
  OrderDataState,
  cleanOrderDataState,
  AccountDataState,
  accountDataSlice,
  cleanAccountDataState,
} from '..';

export const handleCustomerSubscriptionCancelledSSE = async (
  dispatch: Dispatch<any>
): Promise<void> => {
  const apolloClient = initializeApollo(null);

  const customerQuery = await customersAuthedCustomerQuery(apolloClient);

  dispatch(customerSlice.actions.authed(customerQuery.data.authedCustomer));

  const customerDataState = appStorage.get(
    AppStorageKey.DATA_CUSTOMER_STATE,
    cleanCustomerDataState
  ) as CustomerDataState;

  modernVersionBridge.customer.update(
    dispatch,
    customerDataState,
    customerQuery.data.authedCustomer
  );

  const ordersQuery = await ordersListCustomerOrdersQuery(
    apolloClient,
    customerQuery.data.authedCustomer
  );

  dispatch(orderListSlice.actions.update(ordersQuery.data.listCustomerOrders));

  const orderDataState = appStorage.get(
    AppStorageKey.DATA_ORDER_STATE,
    cleanOrderDataState
  ) as OrderDataState;

  modernVersionBridge.orders.update(
    dispatch,
    orderDataState,
    ordersQuery.data.listCustomerOrders
  );

  const accountDataState = appStorage.get(
    AppStorageKey.DATA_ACCOUNT_STATE,
    cleanAccountDataState
  ) as AccountDataState;

  const updatedAccountDataState: AccountDataState = Object.assign({}, {
    ...accountDataState,
    subscriptionCancelled: true,
  } as AccountDataState);

  dispatch(accountDataSlice.actions.update(updatedAccountDataState));
};
