import { Event } from '@hiwaldo/sdk/event';
import { Dispatch } from 'react';
import { handleCartUpdatedSSE } from './cart-updated.sse';
import { handleChargeAttemptFailedSSE } from './charge-attempt-failed.sse';
import { handleCustomerCreditAddedSSE } from './customer-credit-added.sse';
import { handleCustomerCreditDeductedSSE } from './customer-credit-deducted.sse';
import { handleDefaultSSE } from './default.sse';
import { handleOrderCreatedSSE } from './order-created.sse';
import { handleOrderStatusUpdatedSSE } from './order-status-updated.sse';
import { handleRenewalJobStatusUpdatedSSE } from './renewal-job-status-updated.sse';
import { handleCustomerPaymentMethodSetupSucceededSSE } from './customer-payment-setup-succeeded.sse';
import { handleCustomerPrescriptionUpdatedSSE } from './customer-prescription-updated.sse';
import { handleCustomerSubscriptionCancelledSSE } from './customer-subscription-cancelled.sse';
import { handleCustomerSubscriptionPausedSSE } from './customer-subscription-paused.sse';
import { handleCustomerSubscriptionUpdatedSSE } from './customer-subscription-updated.sse';
import { AppStorageKey, appStorage } from '../../helpers';

interface SSEPacket {
  data: Event;
}

export const SSEClient = (dispatch: Dispatch<any>) => {
  const listen = ({ data }: { data: string }) => {
    try {
      const packet = JSON.parse(data);
      appStorage.set(AppStorageKey.SSE_PING, new Date().getTime());

      if (packet.data.ping) return;

      const event = (packet as SSEPacket).data;

      switch (event.name) {
        case 'CartUpdated': {
          handleCartUpdatedSSE(dispatch, event);
          break;
        }
        case 'ChargeAttemptFailed': {
          handleChargeAttemptFailedSSE(dispatch, event);
          break;
        }
        case 'CustomerCreditAdded': {
          handleCustomerCreditAddedSSE(dispatch, event);
          break;
        }
        case 'CustomerCreditDeducted': {
          handleCustomerCreditDeductedSSE(dispatch, event);
          break;
        }
        case 'CustomerPaymentMethodSetupSucceeded': {
          handleCustomerPaymentMethodSetupSucceededSSE(dispatch);
          break;
        }
        case 'CustomerPrescriptionUpdated': {
          handleCustomerPrescriptionUpdatedSSE(dispatch);
          break;
        }
        case 'CustomerSubscriptionUpdated': {
          handleCustomerSubscriptionUpdatedSSE(dispatch);
          break;
        }
        case 'CustomerSubscriptionCancelled': {
          handleCustomerSubscriptionCancelledSSE(dispatch);
          break;
        }
        case 'CustomerSubscriptionPaused': {
          handleCustomerSubscriptionPausedSSE(dispatch);
          break;
        }
        case 'OrderCreated': {
          handleOrderCreatedSSE(dispatch, event);
          break;
        }
        case 'OrderStatusUpdated': {
          handleOrderStatusUpdatedSSE(dispatch, event);
          break;
        }
        case 'RenewalJobStatusUpdated': {
          handleRenewalJobStatusUpdatedSSE(dispatch, event);
          break;
        }
        default: {
          handleDefaultSSE(dispatch);
        }
      }
    } catch (e) {
      console.error('Could not read SSE packet', e);
    }
  };

  return { listen };
};
