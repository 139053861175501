export enum GTMPageEvent {
  WALDO_LOGIN = 'login',
  WALDO_ACCOUNT_VIEW = 'waldo_account_view',
  WALDO_CHECKOUT_BEGIN = 'begin_checkout',
  WALDO_CHECKOUT_LOGIN = 'checkout_login',
  WALDO_TRIAL_FUNNEL_PRODUCT_PAGE_VIEW = 'view_item_list',
  WALDO_TRIAL_FUNNEL_PRODUCT_PAGE_SELECT = 'select_item',
  WALDO_CHECKOUT_ADD_SHIPPING_INFO = 'add_shipping_info',
  WALDO_CHECKOUT_ADD_PAYMENT_INFO = 'add_payment_info',
  WALDO_CHECKOUT_PURCHASE = 'purchase',

  WALDO_CHECKOUT_BILLING_SUBMIT = 'WaldoCheckoutBillingSubmit',
  WALDO_CHECKOUT_BILLING_VIEW = 'WaldoCheckoutBillingView',
  WALDO_CHECKOUT_CONVERSION = 'WaldoCheckoutConversion',
  WALDO_CHECKOUT_PAYMENT_VIEW = 'WaldoCheckoutPaymentView',
  WALDO_CHECKOUT_REGISTER_SUBMIT = 'WaldoCheckoutRegisterSubmit',
  WALDO_CHECKOUT_REGISTER_VIEW = 'WaldoCheckoutRegisterView',
  WALDO_CHECKOUT_REVIEW_NEXT = 'WaldoCheckoutReviewNext',
  WALDO_CHECKOUT_REVIEW_VIEW = 'WaldoCheckoutReviewView',
  WALDO_CHECKOUT_SHIPPING_SUBMIT = 'WaldoCheckoutShippingSubmit',
  WALDO_CHECKOUT_SHIPPING_VIEW = 'WaldoCheckoutShippingView',
  WALDO_PRODUCT_BLUE_LIGHT_GLASSES_ADD = 'WaldoProductBlueLightGlassesAdd',
  WALDO_PRODUCT_BLUE_LIGHT_GLASSES_VIEW = 'WaldoProductBlueLightGlassesView',
  WALDO_PRODUCT_GIFT_CARD_ADD = 'WaldoProductGiftCardAdd',
  WALDO_PRODUCT_GIFT_CARD_VIEW = 'WaldoProductGiftCardView',
  WALDO_PRODUCT_HOME_START = 'WaldoProductHomeStart',
  WALDO_PRODUCT_HOME_VIEW = 'WaldoProductHomeView',
  WALDO_PRODUCT_HYDRATION_DROPS_ADD = 'WaldoProductHydrationDropsAdd',
  WALDO_PRODUCT_HYDRATION_DROPS_VIEW = 'WaldoProductHydrationDropsView',
  WALDO_PRODUCT_ORIGINAL_LENSES_ADD = 'WaldoProductOriginalLensesAdd',
  WALDO_PRODUCT_ORIGINAL_LENSES_VIEW = 'WaldoProductOriginalLensesView',
  WALDO_PRODUCT_VITAMIN_LENSES_ADD = 'WaldoProductVitaminLensesAdd',
  WALDO_PRODUCT_VITAMIN_LENSES_VIEW = 'WaldoProductVitaminLensesView',
  WALDO_TRIAL_FUNNEL_DOCTOR_PAGE_MANUAL = 'WaldoTrialFunnelDoctorPageManual',
  WALDO_TRIAL_FUNNEL_DOCTOR_PAGE_SELECT = 'WaldoTrialFunnelDoctorPageSelect',
  WALDO_TRIAL_FUNNEL_DOCTOR_PAGE_VIEW = 'WaldoTrialFunnelDoctorPageView',
  WALDO_TRIAL_FUNNEL_EYE_POWER_PAGE_SELECT = 'WaldoTrialFunnelEyePowerPageSelect',
  WALDO_TRIAL_FUNNEL_EYE_POWER_PAGE_VIEW = 'WaldoTrialFunnelEyePowerPageView',
  WALDO_TRIAL_FUNNEL_TRIAL_PAGE_SELECT = 'WaldoTrialFunnelFeaturePageView',
  WALDO_TRIAL_FUNNEL_TRIAL_PAGE_VIEW = 'WaldoTrialFunnelFeaturePageView',
  WALDO_TRIAL_FUNNEL_START_PAGE_NEXT = 'WaldoTrialFunnelStartPageNext',
  WALDO_TRIAL_FUNNEL_START_PAGE_VIEW = 'WaldoTrialFunnelStartPageView',
}
