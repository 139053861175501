import { initializeApollo } from '../../server';
import { Dispatch } from 'react';
import {
  customerSlice,
  customerAccountSlice,
  CustomerDataState,
  cleanCustomerDataState,
  modernVersionBridge,
  OrderDataState,
  cleanOrderDataState,
  AccountSubscriptionData,
  AccountDataState,
  cleanAccountDataState,
  accountDataSlice,
} from '..';
import {
  customersAuthedCustomerQuery,
  ordersListCustomerOrdersQuery,
} from '../../server';
import { orderListSlice } from '../redux/orders/order';
import { appStorage, AppStorageKey } from '../../helpers';

export const handleCustomerSubscriptionUpdatedSSE = async (
  dispatch: Dispatch<any>
): Promise<void> => {
  const apolloClient = initializeApollo(null);

  const customerQuery = await customersAuthedCustomerQuery(apolloClient);

  dispatch(customerSlice.actions.authed(customerQuery.data.authedCustomer));

  const customerDataState = appStorage.get(
    AppStorageKey.DATA_CUSTOMER_STATE,
    cleanCustomerDataState
  ) as CustomerDataState;

  modernVersionBridge.customer.update(
    dispatch,
    customerDataState,
    customerQuery.data.authedCustomer
  );

  const ordersQuery = await ordersListCustomerOrdersQuery(
    apolloClient,
    customerQuery.data.authedCustomer
  );

  dispatch(orderListSlice.actions.update(ordersQuery.data.listCustomerOrders));

  const orderDataState = appStorage.get(
    AppStorageKey.DATA_ORDER_STATE,
    cleanOrderDataState
  ) as OrderDataState;

  modernVersionBridge.orders.update(
    dispatch,
    orderDataState,
    ordersQuery.data.listCustomerOrders
  );

  const customerSubscription =
    customerQuery.data.authedCustomer?.activeCustomerSubscription;
  let updatedSubscription: AccountSubscriptionData | undefined;

  if (customerSubscription) {
    const frequencyDays = customerSubscription.frequency
      ? +customerSubscription.frequency
      : 84;

    let frequencyId: number | undefined;
    switch (frequencyDays) {
      case 504:
        frequencyId = 12;
        break;
      case 420:
        frequencyId = 11;
        break;
      case 336:
        frequencyId = 10;
        break;
      case 280:
        frequencyId = 9;
        break;
      case 224:
        frequencyId = 8;
        break;
      case 168:
        frequencyId = 7;
        break;
      case 140:
        frequencyId = 6;
        break;
      case 112:
        frequencyId = 5;
        break;
      case 84:
        frequencyId = 4;
        break;
      case 56:
        frequencyId = 3;
        break;
      case 42:
        frequencyId = 2;
        break;
      case 28:
        frequencyId = 1;
        break;
    }

    const nextRenewalDate = customerSubscription.nextRenewal
      ? new Date(+customerSubscription.nextRenewal)
      : undefined;

    const lastChargeDate = customerSubscription.nextCharge
      ? new Date(+customerSubscription.nextCharge)
      : undefined;

    const nextChargeDate = customerSubscription.lastCharge
      ? new Date(+customerSubscription.lastCharge)
      : undefined;

    const resumeDate = customerSubscription.resumeDate
      ? new Date(+customerSubscription.resumeDate)
      : undefined;

    const nextChargeAmount = customerSubscription.renewalOrder
      ? +customerSubscription.renewalOrder.grandTotalPrice
      : undefined;

    const addedProducts = [];

    if (customerSubscription.productVariants) {
      for (const i in customerSubscription.productVariants) {
        const renewalVariant = customerSubscription.productVariants[i];

        if (renewalVariant.productVariant.handle.includes('drops')) {
          addedProducts.push({
            id: renewalVariant.productVariantId,
            title: renewalVariant.productVariant.title,
            quantity: renewalVariant.quantity,
          });
        }
      }
    }

    updatedSubscription = {
      id: +customerSubscription.id,
      planId: +customerSubscription.plan.id,
      planTitle: customerSubscription.plan.title,
      productId: customerSubscription.plan.products[0].product.id,
      productTitle: customerSubscription.plan.products[0].product.title,
      familyId: customerSubscription.plan.planFamily.id,
      familyTitle: customerSubscription.plan.planFamily.title,
      status: customerSubscription.status,
      frequencyDays,
      frequencyId,
      nextRenewalDate: nextRenewalDate?.toISOString() || undefined,
      lastChargeDate: lastChargeDate?.toISOString() || undefined,
      nextChargeDate: nextChargeDate?.toISOString() || undefined,
      nextChargeAmount,
      preferredDay: customerSubscription.preferredDay,
      prefferedDate: customerSubscription.preferredDate,
      resumeDate: resumeDate?.toISOString() || undefined,
      addedProducts: addedProducts.length ? addedProducts : undefined,
    };

    const accountDataState = appStorage.get(
      AppStorageKey.DATA_ACCOUNT_STATE,
      cleanAccountDataState
    ) as AccountDataState;

    dispatch(
      accountDataSlice.actions.update({
        ...accountDataState,
        subscription: updatedSubscription,
        subscriptionUpdated: true,
        subscriptionCancelled: false,
        subscriptionPaused: false,
      })
    );
  }

  dispatch(
    customerAccountSlice.actions.loadedAccount({
      personal: true,
      delivery: true,
      billing: true,
      payment: true,
      orders: true,
      subscription: {
        plan: true,
        renewal: true,
        prescription: true,
        cost: true,
      },
    })
  );
};
