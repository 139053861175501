import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../';
import { useKlavyioAddListEmailEffect } from '../../../../server';
import {
  cleanNewsletterPageState,
  newsletterPageSlice,
  NewsletterPageState,
} from '../../../redux';

export interface CaptureNewsletterDataEffectReturn {
  enact: (email: string) => void;
  clear: () => void;
  loading: boolean;
  success: boolean | undefined;
  error?: string;
}

export interface CaptureNewsletterDataEffectProps {
  onComplete?: (success: boolean) => void;
  onError?: (error: string) => void;
}

export const useCaptureNewsletterEffect = (
  props?: CaptureNewsletterDataEffectProps
): CaptureNewsletterDataEffectReturn => {
  const dispatch = useDispatch();

  const { pageNewsletterState } = useSelector((state: StoreState) => {
    return {
      pageNewsletterState: state.page.newsletter,
    };
  });

  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const klavyioAddListEmail = useKlavyioAddListEmailEffect({
    onComplete: () => {
      const updatedSuccessState: NewsletterPageState = Object.assign({}, {
        ...pageNewsletterState,
        isSubmitted: true,
        isSubscriber: true,
        loading: false,
        success: true,
      } as NewsletterPageState);

      dispatch(newsletterPageSlice.actions.update(updatedSuccessState));
      klavyioAddListEmail.clear();

      setSuccess(true);
      setLoading(false);

      if (props?.onComplete) {
        props.onComplete(true);
      }
    },
    onError: (error: string) => {
      const updatedErrorState: NewsletterPageState = Object.assign({}, {
        ...pageNewsletterState,
        loading: false,
        success: false,
        errors: [error],
      } as NewsletterPageState);

      dispatch(newsletterPageSlice.actions.update(updatedErrorState));
      klavyioAddListEmail.clear();

      setErrorMessage(error);
      setSuccess(false);
      setLoading(false);

      if (props?.onError) {
        props.onError(error);
      }
    },
  });

  const captureNewsletter = (email: string) => {
    setLoading(true);

    const updatedErrorState: NewsletterPageState = Object.assign({}, {
      ...pageNewsletterState,
      isSubmitted: true,
      loading: true,
      success: false,
    } as NewsletterPageState);

    dispatch(newsletterPageSlice.actions.update(updatedErrorState));

    klavyioAddListEmail.enact('newsletter', email);
  };

  const clear = () => {
    setErrorMessage(undefined);
    setSuccess(undefined);
    setLoading(false);

    dispatch(newsletterPageSlice.actions.update(cleanNewsletterPageState));
  };

  return {
    enact: captureNewsletter,
    clear,
    loading,
    success,
    error: errorMessage,
  };
};
