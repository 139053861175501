import { useRouter } from 'next/router';
import { Route } from '../../../enums';
import { envHostnames } from '../../../environment';
import { useCustomerAuthLogoutEffect } from '../../../state';
import { SiteFeatureList } from '../../../server';
import { useEffect, useState } from 'react';
import { usePageEnvironmentEffect } from '../environment';

export interface RouteItem {
  handle: string;
  label: string;
  info?: string;
  onLinkHandler: (hash?: string) => void;
}

export interface RegionRouteItem {
  handle: string;
  label: string;
  onLinkHandler: (hash?: string) => void;
  onLoginHandler: () => void;
}

export interface ExtendedRouteItem extends RouteItem {
  image: string;
  alt: string;
}

export interface RouteAccountLinks {
  dashboard: RouteItem;
  plan: RouteItem;
  paymentDetails: RouteItem;
  personalDetails: RouteItem;
  shippingAddress: RouteItem;
  billingAddress: RouteItem;
  orderHistory: RouteItem;
  logout: RouteItem;
}

export interface RouteProductLinks {
  vitaminBoostLenses: RouteItem;
  hydraBoostLenses: RouteItem;
  starterkit?: RouteItem;
  eyeDrops?: RouteItem;
  blueLightGlasses?: RouteItem;
  blueLightGlassesSelection?: RouteItem;
}

export interface ExtendedRouteProductLinks {
  vitaminBoostLenses: ExtendedRouteItem;
  hydraBoostLenses: ExtendedRouteItem;
  starterkit?: ExtendedRouteItem;
  eyeDrops?: ExtendedRouteItem;
  blueLightGlasses?: ExtendedRouteItem;
}

export interface RouteCheckoutLinks {
  register: RouteItem;
  login: RouteItem;
  shipping: RouteItem;
  review: RouteItem;
  payment: RouteItem;
  welcome: RouteItem;
}

export interface RouteAboutLinks {
  ourStory: RouteItem;
  ourBlog: RouteItem;
  howWorks: RouteItem;
  visionaries: RouteItem;
  giftCard: RouteItem;
  help: RouteItem;
}

export interface RouteDoctorLinks {
  partner: RouteItem;
  ambassador: RouteItem;
}

export interface RouteFooterLinks {
  ourStory: RouteItem;
  ourBlog: RouteItem;
  reviews: RouteItem;
  visionaries: RouteItem;
  help: RouteItem;
  doctors: RouteItem;
  login: RouteItem;
  sightSavers: RouteItem;
  yotpoRewards?: RouteItem;
}

export interface RouteCompanyLinks {
  privacyPolicy: RouteItem;
  cookiePolicy: RouteItem;
  termsConditions: RouteItem;
}

export interface RouteSocialLinks {
  instagram: ExtendedRouteItem;
  facebook: ExtendedRouteItem;
  twitter: ExtendedRouteItem;
}

export interface RouteRegionLinks {
  us: RegionRouteItem;
  uk: RegionRouteItem;
  se: RegionRouteItem;
  eu: RegionRouteItem;
}

export interface RoutingEffect {
  isRedirecting: boolean;
  homeLink: ExtendedRouteItem;
  getStartedLink: RouteItem;
  accountLink: RouteItem;
  loginLink: RouteItem;
  logoutLink: RouteItem;
  resetLink: RouteItem;
  visionCenterLink: RouteItem;
  howItWorksLink: RouteItem;
  noSalesLink: RouteItem;
  accountLinks: RouteAccountLinks;
  productLinks: RouteProductLinks;
  productLinksExtended: ExtendedRouteProductLinks;
  checkoutLinks: RouteCheckoutLinks;
  aboutLinks: RouteAboutLinks;
  doctorLinks: RouteDoctorLinks;
  footerLinks: RouteFooterLinks;
  companyLinks: RouteCompanyLinks;
  socialLinks: RouteSocialLinks;
  regionLinks: RouteRegionLinks;
}

export const useRoutingEffect = (
  activeFeatures?: SiteFeatureList
): RoutingEffect => {
  const router = useRouter();
  const { getEnvironment, region, application } = usePageEnvironmentEffect();
  const url = getEnvironment('domain', region);

  const [isRedirecting, setRedirecting] = useState<boolean>(false);
  const [isMounted, setMounted] = useState<boolean>(false);

  const [dropsActive] = useState<boolean>(false);

  const customerLogout = useCustomerAuthLogoutEffect();

  const homeLink: ExtendedRouteItem = {
    handle: 'home',
    label: 'Waldo Vision',
    image: '/images/logo.svg',
    alt: 'Waldo Vision',
    onLinkHandler: () => {
      setRedirecting(true);
      router.push(Route.HOME);
    },
  };

  const getStartedLink: RouteItem = {
    handle: 'get-Started',
    label: 'Get Started',
    onLinkHandler: () => {
      setRedirecting(true);
      router.push(Route.GET_STARTED);
    },
  };

  const accountLink: RouteItem = {
    handle: 'account',
    label: 'My Account',
    onLinkHandler: (hash?: string) => {
      setRedirecting(true);

      const route = hash ? `${Route.MY_ACCOUNT}#${hash}` : Route.MY_ACCOUNT;
      router.push(route);
    },
  };

  const loginLink: RouteItem = {
    handle: 'login',
    label: 'Login',
    onLinkHandler: () => {
      setRedirecting(true);
      router.push(Route.LOGIN);
    },
  };

  const logoutLink: RouteItem = {
    handle: 'logout',
    label: 'Logout',
    onLinkHandler: () => {
      setRedirecting(true);
      customerLogout.enact();
    },
  };

  const resetLink: RouteItem = {
    handle: 'reset-password',
    label: 'Reset',
    onLinkHandler: () => {
      setRedirecting(true);
      router.push(Route.RESET_PASSWORD);
    },
  };

  const visionCenterLink: RouteItem = {
    handle: 'vision-center',
    label: 'Find a vision center',
    onLinkHandler: () => {
      setRedirecting(true);
      router.push(Route.FIND_A_VISION_CENTER);
    },
  };

  const howItWorksLink: RouteItem = {
    handle: 'how-it-works',
    label: 'How it works',
    onLinkHandler: () => {
      setRedirecting(true);
      router.push(Route.HOW_IT_WORKS);
    },
  };

  const noSalesLink: RouteItem = {
    handle: 'no-sales',
    label: 'No Sales',
    onLinkHandler: () => {
      setRedirecting(true);
      router.push(Route.NO_SALES);
    },
  };

  const accountLinks: RouteAccountLinks = {
    dashboard: {
      handle: 'dashboard',
      label: 'My account',
      onLinkHandler: accountLink.onLinkHandler,
    },
    plan: {
      handle: 'plan',
      label: 'Plan',
      onLinkHandler: () => {
        accountLink.onLinkHandler('plan');
      },
    },
    paymentDetails: {
      handle: 'payment-details',
      label: 'Payment details',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.ACCOUNT_PAYMENT_DETAILS);
      },
    },
    personalDetails: {
      handle: 'personal-details',
      label: 'Personal details',
      onLinkHandler: () => {
        accountLink.onLinkHandler('personal-details');
      },
    },
    shippingAddress: {
      handle: 'shipping-address',
      label: 'Shipping address',
      onLinkHandler: () => {
        accountLink.onLinkHandler('shipping-address');
      },
    },
    billingAddress: {
      handle: 'billing-address',
      label: 'Billing address',
      onLinkHandler: () => {
        accountLink.onLinkHandler('billing-address');
      },
    },
    orderHistory: {
      handle: 'order-history',
      label: 'Order history',
      onLinkHandler: () => {
        accountLink.onLinkHandler('order-history');
      },
    },
    logout: logoutLink,
  };

  const productLinks: RouteProductLinks = {
    vitaminBoostLenses: {
      handle: 'vitamin-boost-lenses',
      label:
        region === 'US'
          ? 'Daily Contact Lenses'
          : 'Vitamin Daily Contact Lenses',
      info: region === 'US' ? 'with vitamin B12' : '',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(
          region === 'US'
            ? Route.PRODUCTS_HYDRABOOST_PLUS
            : Route.PRODUCTS_VITAMIN
        );
      },
    },
    hydraBoostLenses: {
      handle: 'hydra-boost-lenses',
      label: 'Daily Contact Lenses',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.PRODUCTS_ORIGINAL);
      },
    },
    starterkit:
      region === 'US' && dropsActive
        ? {
            handle: 'eyecare-starter-kit',
            label: 'Daily eyecare duo',
            onLinkHandler: () => {
              setRedirecting(true);
              router.push(Route.PRODUCTS_EYE_STARTER_KIT);
            },
          }
        : undefined,
    eyeDrops:
      region === 'US' && dropsActive
        ? {
            handle: 'eye-drops',
            label: 'Eye Drops',
            onLinkHandler: () => {
              setRedirecting(true);
              router.push(Route.PRODUCT_HYDRATION_DROPS);
            },
          }
        : undefined,
    blueLightGlasses: ['US', 'UK'].includes(region)
      ? {
          handle: 'blue-light-glasses',
          label: 'Blue Light Glasses',
          onLinkHandler: () => {
            setRedirecting(true);
            router.push(Route.PRODUCTS_BLG_GENDER_SELECTION);
          },
        }
      : undefined,
    blueLightGlassesSelection: ['US', 'UK'].includes(region)
      ? {
          handle: 'blue-light-glasses-selection',
          label: 'Blue Light Glasses Selection',
          onLinkHandler: () => {
            setRedirecting(true);
            router.push(Route.PRODUCTS_BLG_CATALOG);
          },
        }
      : undefined,
  };

  const productLinksExtended: ExtendedRouteProductLinks = {
    vitaminBoostLenses: {
      handle: 'vitamin-boost-lenses',
      label:
        region === 'US'
          ? 'Daily Contact Lenses with vitamin B12'
          : 'Vitamin Daily Contact Lenses',
      info:
        region === 'US'
          ? 'An extra hydration boost'
          : 'Featuring a vitamin-infused saline solution for added hydration',
      image: '/images/home/products/hbp-lenses.png',
      alt: 'Daily Contact Lenses with vitamin B12',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(
          region === 'US'
            ? Route.PRODUCTS_HYDRABOOST_PLUS
            : Route.PRODUCTS_VITAMIN
        );
      },
    },
    hydraBoostLenses: {
      handle: 'hydra-boost-lenses',
      label: 'Daily Contact Lenses',
      info: 'Breathable and moisture-rich, providing all-day comfort',
      image: '/images/home/products/hydraboost-nav.png',
      alt: 'Daily Contact Lenses',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.PRODUCTS_ORIGINAL);
      },
    },
    starterkit:
      region === 'US' && dropsActive
        ? {
            handle: 'eyecare-starter-kit',
            label: 'Daily Eyecare Duo',
            info: '20 Daily Lenses + Hydration Drops',
            image: '/images/home/products/eyecare_starter_kit.png',
            alt: 'Daily eyecare duo',
            onLinkHandler: () => {
              setRedirecting(true);
              router.push(Route.PRODUCTS_EYE_STARTER_KIT);
            },
          }
        : undefined,
    eyeDrops:
      region === 'US' && dropsActive
        ? {
            handle: 'eye-drops',
            label: 'Eye Drops',
            info: '100% natural active ingredients, no harsh vasoconstrictors',
            image: '/images/home/products/drops-nav.png',
            alt: 'Eye Drops',
            onLinkHandler: () => {
              setRedirecting(true);
              router.push(Route.PRODUCT_HYDRATION_DROPS);
            },
          }
        : undefined,
    blueLightGlasses: ['US', 'UK'].includes(region)
      ? {
          handle: 'blue-light-glasses',
          label: 'Blue Light Glasses',
          info: 'Choose from 14 stylish and blue light blocking frames for screen heavy days',
          image: '/images/home/products/blg-nav.png',
          alt: 'Blue Light Glasses',
          onLinkHandler: () => {
            setRedirecting(true);
            router.push(Route.PRODUCTS_BLG_GENDER_SELECTION);
          },
        }
      : undefined,
  };

  const checkoutLinks: RouteCheckoutLinks = {
    register: {
      handle: 'checkout-register',
      label: 'Register',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.CHECKOUT_REGISTER);
      },
    },
    login: {
      handle: 'checkout-login',
      label: 'Login',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.CHECKOUT_LOGIN);
      },
    },
    shipping: {
      handle: 'checkout-shipping',
      label: 'Shipping',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.CHECKOUT_SHIPPING);
      },
    },
    review: {
      handle: 'checkout-review',
      label: 'Review',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.CHECKOUT_ORDER_REVIEW);
      },
    },
    payment: {
      handle: 'checkout-payment',
      label: 'Payment',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.CHECKOUT_PAYMENT_DETAILS);
      },
    },
    welcome: {
      handle: 'checkout-welcome',
      label: 'Welcome',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.CHECKOUT_WELCOME_CONGRATS);
      },
    },
  };

  const aboutLinks: RouteAboutLinks = {
    ourStory: {
      handle: 'our-story',
      label: 'Our Story',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.OUR_STORY);
      },
    },
    ourBlog: {
      handle: 'our-blog',
      label: 'Our Blog',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.BLOG);
      },
    },
    howWorks: {
      handle: 'how-it-works',
      label: 'How It Works',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.HOW_IT_WORKS);
      },
    },
    visionaries: {
      handle: 'visionaries',
      label: 'Visionaries',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.VISIONARIES);
      },
    },
    giftCard: {
      handle: 'gift-card',
      label: 'eGift Card',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.PRODUCT_EGIFTCARD);
      },
    },
    help: {
      handle: 'help',
      label: 'Help &amp; FAQs',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = `${url}${Route.HELP}`;
        // router.push(Route.HELP);
      },
    },
  };

  const doctorLinks: RouteDoctorLinks = {
    partner: {
      handle: 'doctor-partner',
      label: 'Become a Waldo Partner',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = envHostnames.domain[application].DOCTOR;
      },
    },
    ambassador: {
      handle: 'doctor-ambassador',
      label: 'Brand Ambassador',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = envHostnames.domain[application].DOCTOR;
      },
    },
  };

  const footerLinks: RouteFooterLinks = {
    ourStory: {
      handle: 'footer-our-story',
      label: 'About us',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.OUR_STORY);
      },
    },
    ourBlog: {
      handle: 'footer-our-blog',
      label: 'Blog',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.BLOG);
      },
    },
    reviews: {
      handle: 'footer-reviews',
      label: 'Reviews',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.REVIEWS);
      },
    },
    visionaries: {
      handle: 'footer-visionaries',
      label: 'Visionaries',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.VISIONARIES);
      },
    },
    help: {
      handle: 'footer-help',
      label: 'Help &amp; Contact',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = `${url}${Route.HELP}`;
        // router.push(Route.HELP);
      },
    },
    doctors: {
      handle: 'footer-doctors',
      label: 'For doctors',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = envHostnames.domain[application].DOCTOR;
      },
    },
    login: {
      handle: 'footer-login',
      label: 'Login',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.LOGIN);
      },
    },
    sightSavers: {
      handle: 'footer-sight-savers',
      label: 'Buy one, give vision',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.SIGHT_SAVERS);
      },
    },
    yotpoRewards: activeFeatures?.accountYotpo
      ? {
          handle: 'footer-yotpo-rewards',
          label: 'WALDO rewards',
          onLinkHandler: () => {
            setRedirecting(true);
            window.location.href = Route.YOTPO_REWARDS;
          },
        }
      : undefined,
  };

  const companyLinks: RouteCompanyLinks = {
    privacyPolicy: {
      handle: 'company-privacy-policy',
      label: 'Privacy policy',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.PRIVACY_POLICY);
      },
    },
    cookiePolicy: {
      handle: 'company-cookie-policy',
      label: 'Cookie policy',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.COOKIE_POLICY);
      },
    },
    termsConditions: {
      handle: 'company-terms-conditions',
      label: 'Terms of service',
      onLinkHandler: () => {
        setRedirecting(true);
        router.push(Route.TERMS_SERVICE);
      },
    },
  };

  const socialLinks: RouteSocialLinks = {
    instagram: {
      handle: 'social-instagram',
      label: 'Instagram',
      image: '/images/instagram.svg',
      alt: 'Instagram',
      onLinkHandler: () => {
        setRedirecting(true);
        window.open(Route.INSTAGRAM, '_blank', 'width=400, height=500');
      },
    },
    facebook: {
      handle: 'social-facebook',
      label: 'Facebook',
      image: '/images/facebook.svg',
      alt: 'Facebook',
      onLinkHandler: () => {
        setRedirecting(true);
        window.open(Route.FACEBOOK, '_blank', 'width=400, height=500');
      },
    },
    twitter: {
      handle: 'social-twitter',
      label: 'Twitter',
      image: '/images/twitter.svg',
      alt: 'Twitter',
      onLinkHandler: () => {
        setRedirecting(true);
        window.open(Route.TWITTER, '_blank', 'width=400, height=500');
      },
    },
  };

  const regionLinks: RouteRegionLinks = {
    us: {
      handle: 'region-us',
      label: 'US',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = envHostnames.domain[application].US;
      },
      onLoginHandler: () => {
        setRedirecting(true);
        window.location.href = `${envHostnames.domain[application].US}/login`;
      },
    },
    uk: {
      handle: 'region-uk',
      label: 'UK',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = envHostnames.domain[application].UK;
      },
      onLoginHandler: () => {
        setRedirecting(true);
        window.location.href = `${envHostnames.domain[application].UK}/login`;
      },
    },
    se: {
      handle: 'region-se',
      label: 'SE',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = envHostnames.domain[application].SE;
      },
      onLoginHandler: () => {
        setRedirecting(true);
        window.location.href = `${envHostnames.domain[application].SE}/login`;
      },
    },
    eu: {
      handle: 'region-eu',
      label: 'EU',
      onLinkHandler: () => {
        setRedirecting(true);
        window.location.href = envHostnames.domain[application].EU;
      },
      onLoginHandler: () => {
        setRedirecting(true);
        window.location.href = `${envHostnames.domain[application].EU}/login`;
      },
    },
  };

  useEffect(() => {
    if (!isMounted) setMounted(true);

    if (isMounted) {
      const handleRouteChange = () => {
        setRedirecting(false);
      };

      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [isMounted]);

  return {
    isRedirecting,
    homeLink,
    getStartedLink,
    accountLink,
    loginLink,
    logoutLink,
    resetLink,
    visionCenterLink,
    howItWorksLink,
    noSalesLink,
    accountLinks,
    productLinks,
    productLinksExtended,
    checkoutLinks,
    aboutLinks,
    doctorLinks,
    footerLinks,
    companyLinks,
    socialLinks,
    regionLinks,
  };
};
