import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { cartParser, customerParser } from '../parser';
import {
  Cart,
  CartDoctor,
  CartPrescription,
  Customer,
  Order,
} from '@hiwaldo/sdk/interfaces';
import {
  basketDataSlice,
  BasketDataState,
  cleanBasketDataState,
  customerDataSlice,
  CustomerDataState,
  orderDataSlice,
  OrderDataState,
} from '../../redux';

export const modernVersionBridge = {
  orders: {
    update: async (
      dispatch: Dispatch<AnyAction>,
      state: OrderDataState,
      data: { rows: number; data: Order[] }
    ) => {
      const orderDataState: OrderDataState = {
        ...state,
        orders: data.data,
        countOrders: data.rows,
      };

      dispatch(orderDataSlice.actions.update(orderDataState));
    },
  },
  customer: {
    update: async (
      dispatch: Dispatch<AnyAction>,
      state: CustomerDataState,
      data: Customer
    ) => {
      const customerDataState = await customerParser.fromCustomerUpdate(
        state,
        data
      );

      dispatch(customerDataSlice.actions.update(customerDataState));
    },
  },
  basket: {
    loading: (
      dispatch: Dispatch<AnyAction>,
      state: BasketDataState,
      loading: boolean
    ) => {
      const basketDataState: BasketDataState = {
        ...state,
        loading,
      };

      dispatch(basketDataSlice.actions.update(basketDataState));
    },
    updateDisplay: async (
      dispatch: Dispatch<AnyAction>,
      state: BasketDataState,
      data: boolean
    ) => {
      const basketDataState: BasketDataState = {
        ...state,
        basketOpen: data,
      };

      dispatch(basketDataSlice.actions.update(basketDataState));
    },
    updateCart: async (
      dispatch: Dispatch<AnyAction>,
      state: BasketDataState,
      data: Cart
    ) => {
      const basketDataState = await cartParser.fromCartUpdate(state, data);

      dispatch(
        basketDataSlice.actions.update({ ...basketDataState, loading: false })
      );
    },
    updateCartPrescription: async (
      dispatch: Dispatch<AnyAction>,
      state: BasketDataState,
      data: CartPrescription
    ) => {
      const basketDataState = await cartParser.fromPrescriptionUpdate(
        state,
        data
      );

      dispatch(
        basketDataSlice.actions.update({ ...basketDataState, loading: false })
      );
    },
    updateCartDoctor: async (
      dispatch: Dispatch<AnyAction>,
      state: BasketDataState,
      data: CartDoctor
    ) => {
      const basketDataState = await cartParser.fromDoctorUpdate(state, data);

      dispatch(
        basketDataSlice.actions.update({ ...basketDataState, loading: false })
      );
    },
    clean: async (dispatch: Dispatch<AnyAction>) => {
      const basketDataState = cleanBasketDataState;

      dispatch(basketDataSlice.actions.update(basketDataState));
    },
  },
};
