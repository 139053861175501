import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  captureNewsletterFormSlice,
  CaptureNewsletterFormState,
  CaptureNewsletterFormValues,
  CaptureNewsletterFormSet,
  useFormInputEffect,
  FormInput,
  StoreState,
  emailValidator,
  cleanCaptureNewsletterFormState,
} from '../../..';

export const useCaptureNewsletterFormSetEffect =
  (): CaptureNewsletterFormSet => {
    const dispatch = useDispatch();

    const captureNewsletterFormState = useSelector(
      (state: StoreState) => state.form.capture.newsletter
    );

    const email = useFormInputEffect({
      id: 'newsletter-email-input',
      name: 'email',
      type: 'email',
      rules: {
        skipEmailUnique: true,
        skipEmailLoqate: true,
      },
      validators: [emailValidator],
      initialValue: captureNewsletterFormState.members.email.value,
      initialValid: captureNewsletterFormState.members.email.isValid,
      initialTouched: captureNewsletterFormState.members.email.isTouched,
      initialBlurred: captureNewsletterFormState.members.email.isBlurred,
      initialErrors: captureNewsletterFormState.members.email.errors,
    });

    const members = {
      email,
    };

    const setSubmitted = () => {
      const updatedCaptureNewsletterFormState: CaptureNewsletterFormState =
        Object.assign({}, {
          ...captureNewsletterFormState,
          isSubmitted: true,
        } as CaptureNewsletterFormState);

      dispatch(
        captureNewsletterFormSlice.actions.update(
          updatedCaptureNewsletterFormState
        )
      );
    };

    const getInputValues = (): CaptureNewsletterFormValues => {
      return {
        email: (members.email.value as string) || null,
      };
    };

    const resetInputValues = (wipe = false) => {
      members.email.doReset(wipe);

      dispatch(
        captureNewsletterFormSlice.actions.update(
          cleanCaptureNewsletterFormState
        )
      );
    };

    useEffect(() => {
      let valid = true;
      let touched = false;

      const formInputs = members as any as { [key: string]: FormInput };

      for (const i in formInputs) {
        if (!formInputs[i].isValid) {
          valid = false;
        }

        if (formInputs[i].isTouched) {
          touched = true;
        }
      }

      const updatedState: CaptureNewsletterFormState = {
        isValid: valid,
        isTouched: touched,
        isLoading: captureNewsletterFormState.isLoading,
        isSubmitted: captureNewsletterFormState.isSubmitted,
        members: {
          email: {
            isValid: members.email.isValid,
            isTouched: members.email.isTouched,
            isBlurred: members.email.isBlurred,
            value: members.email.value,
            errors: members.email.errors,
          },
        },
      };

      if (!_.isEqual(captureNewsletterFormState, updatedState)) {
        dispatch(captureNewsletterFormSlice.actions.update(updatedState));
      }
    }, [members]);

    return {
      isValid: captureNewsletterFormState.isValid,
      isTouched: captureNewsletterFormState.isTouched,
      isSubmitted: captureNewsletterFormState.isSubmitted,
      setSubmitted,
      getInputValues,
      resetInputValues,
      members,
    };
  };
