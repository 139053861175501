import { Dispatch } from 'react';
import { ChargeAttemptFailed } from '@hiwaldo/sdk/event/events/order';
import { jobSlice } from '../redux/renewal/job';

export const handleChargeAttemptFailedSSE = async (
  dispatch: Dispatch<any>,
  event: ChargeAttemptFailed
): Promise<void> => {
  if (!event.data.cartId) {
    const now = new Date();

    dispatch(
      jobSlice.actions.update({
        error: event.data.failureMessage,
        available: true,
        success: false,
        loading: false,
        sent: now.getTime(),
      })
    );
  }
};
