import { Region } from '@hiwaldo/sdk';
import { currentRegion } from '../current-region';

export interface FormatCurrentProps {
  amount: number;
  min?: number;
  max?: number;
}

export function formatCurrency(props: FormatCurrentProps): string {
  let formatter: Intl.NumberFormat;

  const region = currentRegion();

  switch (region) {
    case Region.UK:
      {
        formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits:
            typeof props.min !== 'undefined' ? props.min : 2,
          maximumFractionDigits:
            typeof props.max !== 'undefined' ? props.max : 2,
        });
      }
      break;
    case Region.EU:
      {
        formatter = new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits:
            typeof props.min !== 'undefined' ? props.min : 2,
          maximumFractionDigits:
            typeof props.max !== 'undefined' ? props.max : 2,
        });
      }
      break;
    case Region.SE:
      {
        formatter = new Intl.NumberFormat('sv-SE', {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits:
            typeof props.min !== 'undefined' ? props.min : 2,
          maximumFractionDigits:
            typeof props.max !== 'undefined' ? props.max : 2,
        });
      }
      break;
    case Region.US:
    default: {
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: typeof props.min !== 'undefined' ? props.min : 2,
        maximumFractionDigits: typeof props.max !== 'undefined' ? props.max : 2,
      });
    }
  }

  return formatter.format(props.amount / 100);
}
