export const envGTMKeys = {
  prod: {
    US: 'GTM-MP543QM',
    UK: 'GTM-W7ND9KQ',
    EU: 'GTM-M8VKGG5',
    SE: 'GTM-MRNGLNM',
  },
  uat: {
    US: 'GTM-WGWQW9D',
    UK: 'GTM-M9JNSZQ',
    EU: 'GTM-T2N5JL3',
    SE: 'GTM-MH9PFMQ',
  },
  staging: {
    US: 'GTM-WGWQW9D',
    UK: 'GTM-M9JNSZQ',
    EU: 'GTM-T2N5JL3',
    SE: 'GTM-MH9PFMQ',
  },
  local: {
    US: 'GTM-WGWQW9D',
    UK: 'GTM-M9JNSZQ',
    EU: 'GTM-T2N5JL3',
    SE: 'GTM-MH9PFMQ',
  },
};
