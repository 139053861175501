import { Customer, Order } from '@hiwaldo/sdk/interfaces';
import { ApolloClient, ApolloQueryResult } from 'apollo-boost';
import { LIST_CUSTOMER_ORDERS_GQL } from '../../graphql';

export const ordersListCustomerOrdersQuery = async (
  apolloClient: ApolloClient<any>,
  customer: Customer
): Promise<
  ApolloQueryResult<{
    listCustomerOrders: {
      rows: number;
      data: Order[];
    };
  }>
> => {
  return apolloClient.query({
    query: LIST_CUSTOMER_ORDERS_GQL,
    variables: {
      customerId: customer.id,
    },
    fetchPolicy: 'no-cache',
  });
};
