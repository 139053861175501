import { initializeApollo } from '../../server';
import { Dispatch } from 'react';
import {
  customerSlice,
  customerAccountSlice,
  CustomerDataState,
  cleanCustomerDataState,
  modernVersionBridge,
  OrderDataState,
  cleanOrderDataState,
  cleanAccountDataState,
  AccountDataState,
  accountDataSlice,
  CartState,
} from '..';
import {
  customersAuthedCustomerQuery,
  ordersListCustomerOrdersQuery,
} from '../../server';
import { orderListSlice } from '../redux/orders/order';
import { appStorage, AppStorageKey } from '../../helpers';
import { CustomerPaymentMethod } from '../../enums/customer-payment-method';

export const handleCustomerPaymentMethodSetupSucceededSSE = async (
  dispatch: Dispatch<any>
): Promise<void> => {
  const apolloClient = initializeApollo(null);

  const customerQuery = await customersAuthedCustomerQuery(apolloClient);

  const customer = customerQuery.data.authedCustomer;

  dispatch(customerSlice.actions.authed(customer));

  const customerDataState = appStorage.get(
    AppStorageKey.DATA_CUSTOMER_STATE,
    cleanCustomerDataState
  ) as CustomerDataState;

  modernVersionBridge.customer.update(dispatch, customerDataState, customer);

  const ordersQuery = await ordersListCustomerOrdersQuery(
    apolloClient,
    customer
  );

  dispatch(orderListSlice.actions.update(ordersQuery.data.listCustomerOrders));

  const orderDataState = appStorage.get(
    AppStorageKey.DATA_ORDER_STATE,
    cleanOrderDataState
  ) as OrderDataState;

  modernVersionBridge.orders.update(
    dispatch,
    orderDataState,
    ordersQuery.data.listCustomerOrders
  );

  dispatch(
    customerAccountSlice.actions.loadedAccount({
      personal: true,
      delivery: true,
      billing: true,
      payment: true,
      orders: true,
      subscription: {
        plan: true,
        renewal: true,
        prescription: true,
        cost: true,
      },
    })
  );

  const cartState = appStorage.get(
    AppStorageKey.PAID_CART,
    undefined,
    false
  ) as CartState | undefined;

  const accountDataState = appStorage.get(
    AppStorageKey.DATA_ACCOUNT_STATE,
    cleanAccountDataState
  ) as AccountDataState;

  if (!cartState && accountDataState.page === 'payment-details') {
    const paymentMethod = customer.defaultPaymentMethod as
      | CustomerPaymentMethod
      | undefined;

    const updatedPayment =
      paymentMethod?.method === 'GOOGLE_PAY'
        ? 'google-pay'
        : paymentMethod?.method === 'APPLE_PAY'
        ? 'apple-pay'
        : paymentMethod?.method === 'PAYPAL'
        ? 'paypal'
        : 'stripe';

    const updatedAccountDataState: AccountDataState = Object.assign({}, {
      ...accountDataState,
      updatedPayment: updatedPayment,
    } as AccountDataState);

    dispatch(accountDataSlice.actions.update(updatedAccountDataState));
  }
};
