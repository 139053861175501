import { gql } from 'apollo-boost';

export const ASSIGN_CART_CUSTOMER_GQL = gql`
  mutation doAssignCartCustomer($cartId: ID!, $customerId: ID!) {
    assignCartCustomer(cartId: $cartId, customerId: $customerId) {
      id
      updatedAt
      createdAt
      region
      status
      clientId
      customerId
      subtotalPrice
      taxPrice
      shippingOption {
        shippingPrice
      }
      discountDeduction
      creditDeduction
      grandTotalPrice
      productVariants {
        id
        itemPrice
        linePrice
        quantity
        productVariantId
        productVariant {
          id
          title
          handle
          attributes {
            attribute {
              title
              value
            }
          }
          details {
            productId
            productHandle
            productVariantId
            productVariantHandle
            packSize
            power
            baseCurve
            diameter
            sphere
            cylinder
            axis
            shape
            colour
            volume
          }
          locales {
            id
            region
            title
            sku
            stock
            pricing {
              quantity
              basePrice
              actualPrice
            }
            shippingOptions {
              shippingOption {
                id
                price
                handle
              }
            }
          }
          assets {
            asset {
              path
            }
          }
        }
      }
      discounts {
        id
        cartId
        productVariantId
        discountId
        discountCode
        discountValue
      }
      trials {
        id
        planId
        frequencyId
        itemPrice
        linePrice
        quantity
        trial {
          id
          title
          handle
          plans {
            plan {
              id
              planFamily {
                title
                handle
              }
              title
              handle
              products {
                product {
                  id
                  title
                  handle
                  description
                  primaryVariant {
                    assets {
                      asset {
                        path
                      }
                    }
                  }
                }
              }
              locales {
                region
                quantity
                actualPrice
                basePrice
              }
            }
          }
          products {
            quantity
            product {
              id
              title
              handle
              description
              primaryVariant {
                assets {
                  asset {
                    path
                  }
                }
              }
            }
          }
          bundles {
            quantity
            bundle {
              title
              handle
              products {
                quantity
                product {
                  id
                  title
                  handle
                  description
                  primaryVariant {
                    title
                    handle
                    assets {
                      asset {
                        path
                      }
                    }
                  }
                }
              }
            }
          }
          locales {
            region
            leadTime
          }
        }
      }
    }
  }
`;
