import { currentRegion } from '../current-region';
import { AppCatalog } from './app-catalog.helper';
import {
  CatalogProductVariant,
  CatalogProductVariantLocale,
} from '@hiwaldo/sdk/interfaces';

export interface StoreCatalogRegionalProductVariant {
  variant: CatalogProductVariant;
  variantLocale: CatalogProductVariantLocale;
}

export const appCatalogRegionalProductVariant = (
  data: AppCatalog | undefined,
  variantId: number
): StoreCatalogRegionalProductVariant | undefined => {
  const region = currentRegion();

  let variant: CatalogProductVariant | undefined;
  let variantLocale: CatalogProductVariantLocale | undefined;

  if (data) {
    variant = data.productHydrationDrops.productVariants.find(
      (dropsVariant) => +dropsVariant.id === +variantId
    );

    if (!variant) {
      variant = data.productBlueLightGlasses.productVariants.find(
        (blgVariant) => +blgVariant.id === +variantId
      );
    }

    if (!variant) {
      variant = data.productEGiftCard.productVariants.find(
        (giftVariant) => +giftVariant.id === +variantId
      );
    }

    if (!variant) {
      variant = data?.productHydraBoostDaily.productVariants.find(
        (hydraBoostVariant) => +hydraBoostVariant.id === +variantId
      );
    }

    if (!variant) {
      variant = data?.productVitaminBoostDaily.productVariants.find(
        (vitaminVariant) => +vitaminVariant.id === +variantId
      );
    }
  }

  if (variant) {
    variantLocale = variant.locales.find((locale) => locale.region === region);
  }

  return variant && variantLocale ? { variant, variantLocale } : undefined;
};
