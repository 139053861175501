import { OrderCreated } from '@hiwaldo/sdk/event/events/order';
import { Dispatch } from 'react';
import { GTMPageEvent } from '../../enums';
import { gtmPageSlice } from '../../';
import { calculateGtmOrderItems } from './gtm-calculate-order-items.helper';
import { AppCatalog } from '../../../helpers';

export const gtmCheckoutPaymentInfo = (
  dispatch: Dispatch<any>,
  event: OrderCreated,
  payment: string,
  data: AppCatalog | undefined
) => {
  const items = calculateGtmOrderItems(data, event);

  const couponData = event.data.order?.discountCode
    ? {
        coupon: event.data.order.discountCode,
      }
    : {};

  const checkoutPaymentInfo = {
    ecommerce: {
      currency: items.currency,
      value: items.amount,
      ...couponData,
      payment_type: payment,
      items: items.items,
    },
    order_id: event.data.order.id,
  };

  dispatch(
    gtmPageSlice.actions.update({
      event: GTMPageEvent.WALDO_CHECKOUT_ADD_PAYMENT_INFO,
      ...checkoutPaymentInfo,
    })
  );
};
