export const envYotpoKeys = {
  key: {
    prod: {
      US: 'tT_mYB6YL7LLUhSjb4egbQ',
      UK: '',
      EU: '',
      SE: '',
    },
    uat: {
      US: 'Bfm7JruODnM4X-rw3D93WQ',
      UK: 'Bfm7JruODnM4X-rw3D93WQ',
      EU: '',
      SE: '',
    },
    staging: {
      US: 'Bfm7JruODnM4X-rw3D93WQ',
      UK: 'Bfm7JruODnM4X-rw3D93WQ',
      EU: '',
      SE: '',
    },
    local: {
      US: 'Bfm7JruODnM4X-rw3D93WQ',
      UK: 'Bfm7JruODnM4X-rw3D93WQ',
      EU: '',
      SE: '',
    },
  },
  api: {
    prod: {
      US: 'YftiMIqBpMI2BCM4IPp8Ggtt',
      UK: '',
      EU: '',
      SE: '',
    },
    uat: {
      US: 'DYlkVE2Rw23tGayZQl3smgtt',
      UK: 'DYlkVE2Rw23tGayZQl3smgtt',
      EU: '',
      SE: '',
    },
    staging: {
      US: 'DYlkVE2Rw23tGayZQl3smgtt',
      UK: 'DYlkVE2Rw23tGayZQl3smgtt',
      EU: '',
      SE: '',
    },
    local: {
      US: 'DYlkVE2Rw23tGayZQl3smgtt',
      UK: 'DYlkVE2Rw23tGayZQl3smgtt',
      EU: '',
      SE: '',
    },
  },
};
