export const envLoqateKeys = {
  prod: {
    key: 'EY24-TZ29-KB69-PA42',
  },
  uat: {
    key: 'EY24-TZ29-KB69-PA42',
  },
  staging: {
    key: 'EY24-TZ29-KB69-PA42',
  },
  local: {
    key: 'EY24-TZ29-KB69-PA42',
  },
};
