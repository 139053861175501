import { initializeApollo } from '../../server';
import { Dispatch } from 'react';
import { CartUpdated } from '@hiwaldo/sdk/event/events/order';
import { ordersFindCartByIdQuery } from '../../server';
import { cartParser, legacyVersionBridge } from '../utils';
import { appStorage, AppStorageKey } from '../../helpers';
import {
  BasketDataState,
  basketDataSlice,
  cleanBasketDataState,
} from '../redux';

export const handleCartUpdatedSSE = async (
  dispatch: Dispatch<any>,
  event: CartUpdated
): Promise<void> => {
  if (event.data.cart.status !== 'CHARGED') {
    const apolloClient = initializeApollo(null);

    const cartQuery = await ordersFindCartByIdQuery(
      apolloClient,
      event.data.cart.id
    );

    const basketDataState = appStorage.get(
      AppStorageKey.DATA_BASKET_STATE,
      cleanBasketDataState
    ) as BasketDataState;

    cartParser
      .fromCartUpdate(basketDataState, cartQuery.data.findCartById)
      .then((updatedState) => {
        legacyVersionBridge.cart.update(dispatch, cartQuery.data.findCartById);
        dispatch(basketDataSlice.actions.update(updatedState));
      });
  }
};
