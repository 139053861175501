import { CatalogProductVariantAttributeList } from '@hiwaldo/sdk/interfaces';
import { AppCatalog } from './app-catalog.helper';

export const appCatalogVariantDetails = (
  data: AppCatalog | undefined,
  variantId: number
): CatalogProductVariantAttributeList | undefined => {
  let variant = data?.productHydraBoostDaily.productVariants.find(
    (variant) => +variant.id === variantId
  );

  if (!variant) {
    variant = data?.productHydraBoostTrial.productVariants.find(
      (variant) => +variant.id === variantId
    );
  }

  if (!variant) {
    variant = data?.productVitaminBoostDaily.productVariants.find(
      (variant) => +variant.id === variantId
    );
  }

  if (!variant) {
    variant = data?.productVitaminBoostTrial.productVariants.find(
      (variant) => +variant.id === variantId
    );
  }

  return variant?.details || undefined;
};
