export const envPayPalUrls = {
  prod: {
    US: 'https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    UK: 'https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    EU: 'https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    SE: 'https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
  },
  uat: {
    US: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    UK: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    EU: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    SE: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
  },
  staging: {
    US: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    UK: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    EU: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    SE: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
  },
  local: {
    US: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    UK: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    EU: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
    SE: 'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
  },
};
