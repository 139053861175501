import { Dispatch } from 'react';
import { RenewalJobStatusUpdated } from '@hiwaldo/sdk/event/events/renewal';
import { jobSlice } from '../redux/renewal/job';

export const handleRenewalJobStatusUpdatedSSE = async (
  dispatch: Dispatch<any>,
  event: RenewalJobStatusUpdated
): Promise<void> => {
  if (
    ['FLAGGED', 'FAILED'].includes(event.data.renewalJob.status) &&
    event.data.renewalJob.outcome !== 'charge attempt failed'
  ) {
    const now = new Date();

    dispatch(
      jobSlice.actions.update({
        error: 'There was a problem processing your order',
        available: true,
        success: false,
        loading: false,
        sent: now.getTime(),
      })
    );
  }
};
