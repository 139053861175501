import { Region } from '@hiwaldo/sdk/enums';

export function currencyLabel(region: Region, uppercase = false): string {
  switch (region) {
    case Region.US: {
      return uppercase ? 'USD' : 'usd';
    }
    case Region.UK: {
      return uppercase ? 'GBP' : 'gbp';
    }
    case Region.EU: {
      return uppercase ? 'EUR' : 'eur';
    }
    case Region.SE: {
      return uppercase ? 'SE' : 'sek';
    }
  }
}
