import { formatCurrency } from '../format-currency';
import { currentRegion } from '../current-region';
import {
  CatalogPlan,
  CatalogPlanLocalePrice,
  CatalogProductVariant,
  CatalogProductVariantLocale,
  CatalogTrial,
  CatalogTrialLocale,
} from '@hiwaldo/sdk/interfaces';

export interface StoreCatalogRegionalTrialPlan {
  trial: CatalogTrial;
  trialLocale: CatalogTrialLocale;
  plan: CatalogPlan;
  planLocalePrice: CatalogPlanLocalePrice;
  lensVariant: CatalogProductVariant;
  lensVariantLocale: CatalogProductVariantLocale;
  subtotal: string;
  original: string;
  total: string;
}

export const appCatalogRegionalTrialPlan = (
  trial: CatalogTrial,
  planId: number,
  includesDrops: boolean
): StoreCatalogRegionalTrialPlan | undefined => {
  const region = currentRegion();

  const trialLocale = trial.locales.find((locale) => locale.region === region);

  if (trialLocale) {
    const trialPlan = trial.plans.find(
      (planTrial) => +planTrial.plan.id === planId
    );

    if (trialPlan) {
      const plan = trialPlan.plan;
      const planLocalePrice = plan.locales.find(
        (planLocale) => planLocale.region === region
      );

      const lensVariant = plan.products[0].product.primaryVariant;
      const lensVariantLocale = lensVariant
        ? lensVariant.locales.find(
            (productVariantLocale) => productVariantLocale.region === region
          )
        : undefined;

      const actualPrice =
        includesDrops && planLocalePrice?.actualPrice
          ? planLocalePrice.actualPrice + 1000
          : planLocalePrice?.actualPrice;

      const basePrice =
        includesDrops && planLocalePrice?.basePrice
          ? planLocalePrice.basePrice + 1000
          : planLocalePrice?.basePrice;

      if (planLocalePrice && lensVariant && lensVariantLocale) {
        const subtotal = formatCurrency({
          amount: actualPrice || 0,
        });
        const original = formatCurrency({
          amount: basePrice || 0,
        });
        const total = formatCurrency({
          amount: actualPrice || 0,
        });

        return {
          trial,
          trialLocale,
          plan,
          planLocalePrice,
          lensVariant,
          lensVariantLocale,
          subtotal,
          original,
          total,
        };
      }
    }
  }

  return undefined;
};
