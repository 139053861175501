import { Cart, Customer } from '@hiwaldo/sdk/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomerAccountType,
  StoreState,
  basketDataSlice,
  cartParser,
  useCustomerAuthLogoutEffect,
} from '../../../state';
import {
  AssignCartCustomerInput,
  useAssignCartCustomerEffect,
  useSSEAuthEffect,
} from '../../../server';

export interface CustomerEffect {
  customer: Customer | undefined;
  type: CustomerAccountType | undefined;
  customBilling: boolean;
  includesShippingBilling: boolean;
  includesPaymentDetails: boolean;
  includesSubscription: boolean;
  includesPrescription: boolean;
  includesBothEyes: boolean;
  includesDuplicateEyes: boolean;
  assignCart: (data: AssignCartCustomerInput) => void;
  refreshSSE: () => void;
  logout: () => void;
}

export const useCustomerEffect = (): CustomerEffect => {
  const dispatch = useDispatch();

  const { basketDataState, customerDataState } = useSelector(
    (state: StoreState) => ({
      basketDataState: state.data.basket,
      customerDataState: state.data.customer,
    })
  );

  const { customer } = customerDataState;

  const sseAuth = useSSEAuthEffect();

  const logout = useCustomerAuthLogoutEffect();

  const assignCartCustomer = useAssignCartCustomerEffect({
    onComplete: (cart: Cart) => {
      assignCartCustomer.clear();

      cartParser.fromCartUpdate(basketDataState, cart).then((updatedCart) => {
        dispatch(basketDataSlice.actions.update(updatedCart));
      });
    },
    onError: () => {
      assignCartCustomer.clear();
    },
  });

  return {
    customer,
    type: customerDataState.accountType,
    customBilling: customerDataState.customBilling,
    includesShippingBilling: customerDataState.includesShippingBilling,
    includesPaymentDetails: customerDataState.includesPaymentDetails,
    includesSubscription: customerDataState.includesSubscription,
    includesPrescription: customerDataState.includesPrescription,
    includesBothEyes: customerDataState.includesBothEyes,
    includesDuplicateEyes: customerDataState.includesDuplicateEyes,
    assignCart: assignCartCustomer.enact,
    refreshSSE: sseAuth.refresh,
    logout: logout.enact,
  };
};
