import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as environment from '../../../../environment';
import { sseAuthAxios } from '../../../axios';
import { SSEClient, StoreState } from '../../../../state';
import {
  AppStorageKey,
  appStorage,
  currentEnvironment,
} from '../../../../helpers';

export type SSEAuthEffectArgs = {
  onComplete?: (customerId: number) => void;
  onError?: (error: string) => void;
};

export type SSEAuthEffectReturn = {
  refresh: () => void;
};

let eventInterval: NodeJS.Timeout | undefined;
let eventSource: EventSource | undefined;

export const startEventSource = (url: string): EventSource => {
  if (typeof eventSource === 'undefined') {
    eventSource = new EventSource(url);
  }

  return eventSource;
};

export const stopEventSource = (): void => {
  if (eventSource) {
    eventSource.close();
    eventSource = undefined;
  }
};

export const isEventSourceListening = (): boolean => {
  return !(typeof eventSource === 'undefined');
};

export const useSSEAuthEffect = (
  args?: SSEAuthEffectArgs
): SSEAuthEffectReturn => {
  const dispatch = useDispatch();

  const { customerDataState } = useSelector((state: StoreState) => {
    return {
      customerDataState: state.data.customer,
    };
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [listening, setListening] = useState<boolean>(false);

  const application = currentEnvironment();
  const externalGateway = environment.envApolloUrls[application].external;

  const sseClient = SSEClient(dispatch);

  const clear = () => {
    stopEventSource();
    clearInterval(eventInterval);
    eventInterval = undefined;

    setListening(false);
  };

  const sseAuth = (customerId: number) => {
    setLoading(true);
    appStorage.remove(AppStorageKey.SSE_TOKEN);
    appStorage.remove(AppStorageKey.SSE_PING);

    sseAuthAxios()
      .then((data) => {
        if (data.response) {
          const {
            response: { sseToken },
          } = data;

          const eventStream = startEventSource(
            `${externalGateway}/sse/myAccount?auth=${sseToken}&customerId=${customerId}`
          );

          eventStream.addEventListener('message', sseClient.listen);

          setTimeout(() => {
            appStorage.set(AppStorageKey.SSE_PING, new Date().getTime());

            eventInterval = setInterval(() => {
              const currentTimestamp = new Date().getTime();
              const ssePing = appStorage.get(
                AppStorageKey.SSE_PING,
                0
              ) as number;
              const sseToken = appStorage.get(
                AppStorageKey.SSE_TOKEN,
                undefined
              ) as string | undefined;

              if (ssePing && sseToken && currentTimestamp - ssePing > 30000) {
                clear();
              }
            }, 10000);
          }, 60000);

          setListening(true);
          setLoading(false);

          if (args?.onComplete) {
            args.onComplete(customerId);
          }
        } else {
          const errorMessage = 'SSE Auth: Failed to fetch token';
          console.error(errorMessage);
          setLoading(false);

          if (args?.onError) {
            args.onError(errorMessage);
          }
        }
      })
      .catch(() => {
        const exceptionMessage = 'SSE Auth: Exception';
        console.error(exceptionMessage);
        setLoading(false);

        if (args?.onError) {
          args.onError(exceptionMessage);
        }
      });
  };

  useEffect(() => {
    if (
      customerDataState.customer &&
      customerDataState.allowSSE &&
      !listening &&
      !loading
    ) {
      sseAuth(customerDataState.customer.id);
    }

    if (
      listening &&
      (!customerDataState.customer || !customerDataState.allowSSE)
    ) {
      clear();
    }
  }, [customerDataState.allowSSE, listening, loading]);

  return {
    refresh: clear,
  };
};
