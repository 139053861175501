import { CatalogProductVariant } from '@hiwaldo/sdk/interfaces';
import { AppCatalog } from './app-catalog.helper';

export interface StoreCatalogPowerVariants {
  hydraBoostDailyVariant: CatalogProductVariant;
  hydraBoostTrialVariant: CatalogProductVariant;
  vitaminBoostDailyVariant: CatalogProductVariant;
  vitaminBoostTrialVariant: CatalogProductVariant;
}

export const appCatalogPowerVariants = (
  data: AppCatalog | undefined,
  power: string
): StoreCatalogPowerVariants => {
  const hydraBoostDaily = data?.productHydraBoostDaily.productVariants.find(
    (variant) => variant.details?.power === power
  );

  const hydraBoostTrial = data?.productHydraBoostTrial.productVariants.find(
    (variant) => variant.details?.power === power
  );

  const vitaminBoostDaily = data?.productVitaminBoostDaily.productVariants.find(
    (variant) => variant.details?.power === power
  );

  const vitaminBoostTrial = data?.productVitaminBoostTrial.productVariants.find(
    (variant) => variant.details?.power === power
  );

  return {
    hydraBoostDailyVariant: hydraBoostDaily as CatalogProductVariant,
    hydraBoostTrialVariant: hydraBoostTrial as CatalogProductVariant,
    vitaminBoostDailyVariant: vitaminBoostDaily as CatalogProductVariant,
    vitaminBoostTrialVariant: vitaminBoostTrial as CatalogProductVariant,
  };
};
