import { initializeApollo } from '../../server';
import { Dispatch } from 'react';
import { CustomerCreditDeducted } from '@hiwaldo/sdk/event/events/customer';
import { customersAuthedCustomerQuery } from '../../server';
import { appStorage, AppStorageKey } from '../../helpers';
import {
  CustomerDataState,
  cleanCustomerDataState,
  customerSlice,
  modernVersionBridge,
} from '..';

export const handleCustomerCreditDeductedSSE = async (
  dispatch: Dispatch<any>,
  event: CustomerCreditDeducted
): Promise<void> => {
  if (event.data.creditDeducted > 0) {
    const apolloClient = initializeApollo(null);

    const customerQuery = await customersAuthedCustomerQuery(apolloClient);

    dispatch(customerSlice.actions.authed(customerQuery.data.authedCustomer));

    const customerDataState = appStorage.get(
      AppStorageKey.DATA_CUSTOMER_STATE,
      cleanCustomerDataState
    ) as CustomerDataState;

    modernVersionBridge.customer.update(
      dispatch,
      customerDataState,
      customerQuery.data.authedCustomer
    );
  }
};
