import { gql } from 'apollo-boost';

export const AUTHED_CUSTOMER_GQL = gql`
  {
    authedCustomer {
      id
      region
      firstName
      lastName
      dob
      email
      credit
      matchAddress
      lastOrderDate
      billingAddress {
        active
        phone
        country
        postalCode
        state
        city
        line2
        line1
        company
        lastName
        firstName
        id
      }
      shippingAddress {
        active
        customerId
        phone
        postalCode
        country
        state
        city
        line2
        line1
        company
        lastName
        firstName
        id
      }
      activePrescription {
        id
        status
        expires
        doctorAuth {
          id
          status
          reviewAt
          doctorId
          doctorParty {
            firstName
            lastName
            company
            phone
          }
        }
        leftEye {
          power
          eyeType
          productVariantId
        }
        rightEye {
          power
          eyeType
          productVariantId
        }
      }
      defaultPaymentMethod {
        id
        active
        gateway
        method
        brand
        last4
        expiryMonth
        expiryYear
      }
      activeCustomerSubscription {
        id
        createdAt
        status
        nextRenewal
        frequency
        preferredDay
        preferredDate
        resumeDate
        plan {
          id
          planFamily {
            title
            handle
            id
          }
          title
          handle
          frequencies {
            frequency {
              frequency
            }
          }
          pauseOptions {
            pauseOption {
              value
            }
          }
          products {
            product {
              id
              title
            }
            locales {
              quantity
            }
          }
          locales {
            region
            actualPrice
            basePrice
          }
        }
        renewalOrder {
          id
          grandTotalPrice
          creditDeduction
          discountDeduction
          taxPrice
          subtotalPrice
          shippingOption {
            shippingPrice
          }
          discounts {
            discountValue
            discountCode
          }
          productVariants {
            id
            productVariantId
            quantity
            linePrice
            productVariant {
              title
              handle
              attributes {
                attribute {
                  handle
                  value
                  property {
                    title
                  }
                }
              }
            }
          }
        }
        productVariants {
          quantity
          productVariant {
            handle
          }
        }
      }
    }
  }
`;
