import { CatalogProductVariant } from '@hiwaldo/sdk/interfaces';
import { AppCatalog } from './app-catalog.helper';
import { appCatalogVariantDetails } from './app-catalog-variant-details.helper';
import { appCatalogPowerVariants } from './app-catalog-power-variants.helper';

export const appCatalogOTPVariant = (
  data: AppCatalog | undefined,
  variantId: number,
  productType?: 'daily' | 'vitamin'
): CatalogProductVariant | undefined => {
  const productVariantDetails = appCatalogVariantDetails(data, variantId);

  const powerVariants = productVariantDetails?.power
    ? appCatalogPowerVariants(data, productVariantDetails.power)
    : undefined;

  let productVariant: CatalogProductVariant | undefined;

  if (productType === 'daily') {
    productVariant = powerVariants?.hydraBoostDailyVariant;
  } else if (productType === 'vitamin') {
    productVariant = powerVariants?.vitaminBoostDailyVariant;
  } else {
    productVariant = productVariantDetails?.productHandle?.match(/vitamin/)
      ? powerVariants?.vitaminBoostDailyVariant
      : powerVariants?.hydraBoostDailyVariant;
  }

  return productVariant;
};
