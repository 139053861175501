import { currentRegion } from '../current-region';
import { AppCatalog } from './app-catalog.helper';
import {
  CatalogProduct,
  CatalogProductVariant,
  CatalogProductVariantLocale,
} from '@hiwaldo/sdk/interfaces';

export interface StoreCatalogRegionalProduct {
  product: CatalogProduct;
  variants: {
    variant: CatalogProductVariant;
    variantLocale: CatalogProductVariantLocale;
  }[];
}

export const appCatalogRegionalProduct = (
  data: AppCatalog | undefined,
  productId: number
): StoreCatalogRegionalProduct | undefined => {
  const region = currentRegion();

  let product: CatalogProduct | undefined;

  const variants: {
    variant: CatalogProductVariant;
    variantLocale: CatalogProductVariantLocale;
  }[] = [];

  if (data) {
    switch (productId) {
      case 1:
        product = data.productHydraBoostDaily;
        break;
      case 2:
        product = data.productHydraBoostTrial;
        break;
      case 3:
        product = data.productVitaminBoostDaily;
        break;
      case 4:
        product = data.productVitaminBoostTrial;
        break;
      case 5:
        product = data.productBlueLightGlasses;
        break;
      case 6:
        product = data.productHydrationDrops;
        break;
      case 7:
        product = data.productEGiftCard;
        break;
    }

    if (product) {
      product.productVariants.forEach((variant) => {
        const variantLocale = variant.locales.find(
          (locale) => locale.region === region
        );

        if (variantLocale) variants.push({ variant, variantLocale });
      });
    }
  }

  return product && variants.length ? { product, variants } : undefined;
};
