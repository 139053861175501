import { CatalogTrial } from '@hiwaldo/sdk/interfaces';
import { currentRegion } from '../current-region';
import { formatCurrency } from '../format-currency';
import { AppCatalog } from './app-catalog.helper';
import {
  StoreCatalogRegionalTrialPlan,
  appCatalogRegionalTrialPlan,
} from './app-catalog-regional-trial-plan.helper';

export interface StoreCatalogRegionalTrialProduct {
  title: string;
  subtitle: string;
  cost: string;
  icon: string;
}

export interface StoreCatalogRegionalTrial
  extends StoreCatalogRegionalTrialPlan {
  products: StoreCatalogRegionalTrialProduct[];
}

export const appCatalogRegionalTrial = (
  data: AppCatalog | undefined,
  trialId: 1 | 2 | 3 | 4,
  planId: 15 | 16 | undefined
): StoreCatalogRegionalTrial | undefined => {
  const region = currentRegion();

  let trial: CatalogTrial | undefined;
  let regionalTrialPlan: StoreCatalogRegionalTrialPlan | undefined;
  const products: StoreCatalogRegionalTrialProduct[] = [];

  if (!planId) {
    planId = [3, 4].includes(trialId) ? 16 : 15;
  }

  if (data) {
    switch (trialId) {
      case 2:
        trial = data.trialHydraBoostBundle || undefined;

        regionalTrialPlan = trial
          ? appCatalogRegionalTrialPlan(trial, planId, region === 'US')
          : undefined;

        products.push({
          title: regionalTrialPlan?.lensVariantLocale?.title || '',
          subtitle: '180 lenses',
          cost: formatCurrency({ amount: 80 }) + ' per lens',
          icon: '/images/products/daily_contact_lens.png',
        });

        if (region === 'US') {
          products.push({
            title: 'Hydration Drops',
            subtitle: '1 bottle',
            cost: formatCurrency({ amount: 1000 }) + ' per bottle',
            icon: '/images/drop-quick.png',
          });
        }
        break;
      case 3:
        trial = data.trialVitaminBoost || undefined;

        regionalTrialPlan = trial
          ? appCatalogRegionalTrialPlan(trial, planId, false)
          : undefined;

        products.push({
          title: regionalTrialPlan?.lensVariantLocale?.title || '',
          subtitle: '180 lenses',
          cost: formatCurrency({ amount: 80 }) + ' per lens',
          icon: '/images/funnel/hbp-lenses-cart.png',
        });
        break;
      case 4:
        trial = data.trialVitaminBoostBundle || undefined;

        regionalTrialPlan = trial
          ? appCatalogRegionalTrialPlan(trial, planId, region === 'US')
          : undefined;

        products.push({
          title: regionalTrialPlan?.lensVariantLocale?.title || '',
          subtitle: '180 lenses',
          cost: formatCurrency({ amount: 80 }) + ' per lens',
          icon: '/images/funnel/hbp-lenses-cart.png',
        });

        if (region === 'US') {
          products.push({
            title: 'Hydration Drops',
            subtitle: '1 bottle',
            cost: formatCurrency({ amount: 1000 }) + ' per bottle',
            icon: '/images/drop-quick.png',
          });
        }
        break;
      default:
        trial = data.trialHydraBoost || undefined;

        regionalTrialPlan = trial
          ? appCatalogRegionalTrialPlan(trial, planId, false)
          : undefined;

        products.push({
          title: regionalTrialPlan?.lensVariantLocale?.title || '',
          subtitle: '180 lenses',
          cost: formatCurrency({ amount: 80 }) + ' per lens',
          icon: '/images/products/daily_contact_lens.png',
        });
        break;
    }
  }

  return regionalTrialPlan
    ? {
        ...regionalTrialPlan,
        products,
      }
    : undefined;
};
