import { ApolloClient, ApolloQueryResult } from 'apollo-boost';
import { FIND_CUSTOMER_BY_EMAIL_GQL } from '../../graphql';

export const customersFindCustomerByEmailQuery = async (
  apolloClient: ApolloClient<any>,
  email: string
): Promise<
  ApolloQueryResult<{
    findCustomerByEmail: boolean;
  }>
> => {
  return apolloClient.query({
    query: FIND_CUSTOMER_BY_EMAIL_GQL,
    variables: {
      email: `${email}`,
    },
    fetchPolicy: 'no-cache',
  });
};
