import { Cart, CartDoctor, CartPrescription } from '@hiwaldo/sdk/interfaces';
import { BasketDataState } from '../../redux';

export const cartParser = {
  fromCartUpdate: async (
    state: BasketDataState,
    cart: Cart
  ): Promise<BasketDataState> => {
    const updatedState: BasketDataState = { ...state };

    const doctor = updatedState.cart?.doctor
      ? updatedState.cart.doctor
      : undefined;

    const prescription = cart.prescription
      ? cart.prescription
      : updatedState.cart?.prescription
      ? updatedState.cart.prescription
      : undefined;

    updatedState.cart = { ...cart, doctor, prescription };

    updatedState.basketConverted = false;
    updatedState.customerAssigned = !!cart.customerId;

    updatedState.countProductVariants =
      (updatedState.cart?.productVariants || []).length || 0;
    updatedState.countTrials = (updatedState.cart?.trials || []).length || 0;
    updatedState.countItems =
      updatedState.countProductVariants + updatedState.countBundles;

    updatedState.includesTrial = updatedState.countTrials > 0 ? true : false;
    updatedState.includesBundle = false;
    updatedState.includesLenses = updatedState.includesTrial ? true : false;
    updatedState.includesBLG = false;
    updatedState.includesDrops = false;
    updatedState.includesDoctor = !!updatedState.cart?.doctor;
    updatedState.includesPrescription = !!(
      updatedState.cart?.prescription &&
      (updatedState.cart!.prescription?.leftEyeProductVariant ||
        updatedState.cart!.prescription?.rightEyeProductVariant)
    );

    updatedState.includesTax = updatedState.cart.taxPrice > 0;

    if (
      updatedState.includesDoctor &&
      updatedState.includesPrescription &&
      updatedState.includesTrial
    ) {
      updatedState.countItems++;
    }

    for (const i in updatedState.cart?.productVariants) {
      const cartVariant = updatedState.cart.productVariants[i];

      if (cartVariant.productVariant.handle.includes('lenses')) {
        updatedState.includesLenses = true;
      }

      if (
        cartVariant.productVariant.handle.includes('knox|murphy|nikka|stacia')
      ) {
        updatedState.includesBLG = true;
      }

      if (cartVariant.productVariant.handle.includes('drops')) {
        updatedState.includesDrops = true;
      }
    }

    for (const x in updatedState.cart?.trials) {
      const cartTrial = updatedState.cart.trials[x];

      if (cartTrial.trial.handle.includes('bundle')) {
        updatedState.includesDrops = true;
        updatedState.includesBundle = true;
      }
    }

    updatedState.includesBothEyes = !!(
      updatedState.includesPrescription &&
      updatedState.cart!.prescription?.leftEyeProductVariant &&
      updatedState.cart!.prescription?.rightEyeProductVariant
    );

    updatedState.leftEye =
      updatedState.cart.prescription?.leftEyeProductVariant || undefined;

    updatedState.rightEye =
      updatedState.cart.prescription?.rightEyeProductVariant || undefined;

    updatedState.includesDuplicateEyes = !!(
      updatedState.leftEye &&
      updatedState.rightEye &&
      updatedState.leftEye.id === updatedState.rightEye.id
    );

    return updatedState;
  },

  fromDoctorUpdate: async (
    state: BasketDataState,
    cartDoctor: CartDoctor
  ): Promise<BasketDataState> => {
    const updatedState: BasketDataState = { ...state };

    const cart = updatedState.cart!;
    const doctor = cartDoctor;

    updatedState.cart = { ...cart, doctor };

    updatedState.countProductVariants =
      (updatedState.cart?.productVariants || []).length || 0;
    updatedState.countTrials = (updatedState.cart?.trials || []).length || 0;
    updatedState.countItems =
      updatedState.countProductVariants + updatedState.countBundles;

    updatedState.includesLenses = true;
    updatedState.includesDoctor = !!updatedState.cart?.doctor;
    updatedState.includesPrescription = !!(
      updatedState.cart?.prescription &&
      (updatedState.cart!.prescription?.leftEyeProductVariant ||
        updatedState.cart!.prescription?.rightEyeProductVariant)
    );

    if (
      updatedState.includesDoctor &&
      updatedState.includesPrescription &&
      updatedState.includesTrial
    ) {
      updatedState.countItems++;
    }

    updatedState.includesBothEyes = !!(
      updatedState.includesPrescription &&
      updatedState.cart!.prescription?.leftEyeProductVariant &&
      updatedState.cart!.prescription?.rightEyeProductVariant
    );

    updatedState.leftEye =
      updatedState.cart.prescription?.leftEyeProductVariant || undefined;

    updatedState.rightEye =
      updatedState.cart.prescription?.rightEyeProductVariant || undefined;

    updatedState.includesDuplicateEyes = !!(
      updatedState.leftEye &&
      updatedState.rightEye &&
      updatedState.leftEye.id === updatedState.rightEye.id
    );

    return updatedState;
  },

  fromPrescriptionUpdate: async (
    state: BasketDataState,
    cartPrescription: CartPrescription
  ): Promise<BasketDataState> => {
    const updatedState: BasketDataState = { ...state };

    const cart = updatedState.cart!;
    const prescription = cartPrescription;

    updatedState.cart = { ...cart, prescription };

    updatedState.countProductVariants =
      (updatedState.cart?.productVariants || []).length || 0;
    updatedState.countTrials = (updatedState.cart?.trials || []).length || 0;
    updatedState.countItems =
      updatedState.countProductVariants + updatedState.countBundles;

    updatedState.includesLenses = true;
    updatedState.includesDoctor = !!updatedState.cart?.doctor;
    updatedState.includesPrescription = !!(
      updatedState.cart?.prescription &&
      (updatedState.cart!.prescription?.leftEyeProductVariant ||
        updatedState.cart!.prescription?.rightEyeProductVariant)
    );

    if (
      updatedState.includesDoctor &&
      updatedState.includesPrescription &&
      updatedState.includesTrial
    ) {
      updatedState.countItems++;
    }

    updatedState.includesBothEyes = !!(
      updatedState.includesPrescription &&
      updatedState.cart!.prescription?.leftEyeProductVariant &&
      updatedState.cart!.prescription?.rightEyeProductVariant
    );

    updatedState.leftEye =
      updatedState.cart.prescription?.leftEyeProductVariant || undefined;

    updatedState.rightEye =
      updatedState.cart.prescription?.rightEyeProductVariant || undefined;

    updatedState.includesDuplicateEyes = !!(
      updatedState.leftEye &&
      updatedState.rightEye &&
      updatedState.leftEye.id === updatedState.rightEye.id
    );

    return updatedState;
  },
};
