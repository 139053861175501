import axios from 'axios';
import * as environment from '../../../environment';
import { currentRegion, currentEnvironment } from '../../../helpers';

export interface KlavyioAddListEmailResponse {
  response: any;
  error: string | null;
}

export const klavyioAddListEmailAxios = async (
  listName: string,
  email: string
): Promise<KlavyioAddListEmailResponse> => {
  const region = currentRegion();
  const application = currentEnvironment();

  const externalGateway = environment.envApolloUrls[application].external;

  let error: string | null = null;
  let response: any = null;

  try {
    const result = await axios.post(
      externalGateway + '/klaviyo/list',
      { listName, email },
      {
        headers: {
          'x-waldo-region': region,
        },
      }
    );

    response = result as any;
  } catch (errorResponse) {
    console.error('Klavyio email list error', errorResponse);
    error = 'Klavyio email list error: Failed to send';
  }

  return { response, error };
};
