export enum Route {
  HOME = '/',
  ABOUT_US = '/about-us',
  OUR_STORY = '/our-story',
  HOW_IT_WORKS = '/how-it-works',
  VISIONARIES = '/visionaries',
  HELP_AND_FAQ = '/help',
  MY_ACCOUNT = '/account',
  LOGIN = '/login',
  DOCTORS = '/doctors',
  LOGOUT = '/',
  CHECKOUT_LOGIN = '/checkout/login',
  CHECKOUT_REGISTER = '/checkout/register',
  CHECKOUT_SHIPPING = '/checkout/shipping',
  CHECKOUT_ORDER_REVIEW = '/checkout/order-review',
  CHECKOUT_PAYMENT_DETAILS = '/checkout/payment-details',
  CHECKOUT_WELCOME_CONGRATS = '/checkout/welcome/congrats',
  MEMBER_LOGIN = '/doctor/login',
  PATIENT_REGISTER = '/doctor/patient/register',
  PATIENT_PRESCRIPTION = '/doctor/patient/prescription',
  PATIENT_PLAN = '/doctor/patient/plan',
  PATIENT_SHIPPING = '/doctor/patient/shipping',
  PATIENT_ORDER_REVIEW = '/doctor/patient/order-review',
  PATIENT_PAYMENT_DETAILS = '/doctor/patient/payment-details',
  PATIENT_WELCOME_CONGRATS = '/doctor/patient/welcome/congrats',
  // CHECKOUT_WELCOME_SURVEY_ONE = '/checkout/welcome/congrats#hear-about-us',
  // CHECKOUT_WELCOME_SURVEY_TWO = '/checkout/welcome/survey-why-waldo#convinced',
  // CHECKOUT_WELCOME_SURVEY_THREE = '/checkout/welcome/survey-convinced-waldo#other-brand-used',
  // CHECKOUT_WELCOME_SURVEY_FOUR = '/checkout/welcome/survey-other-brands-waldo#product-interested',
  // CHECKOUT_WELCOME_SURVEY_FIVE = '/checkout/welcome/survey-products-interested-waldo#birthdate',
  // CHECKOUT_WELCOME_SURVEY_SIX = '/checkout/welcome/survey-birthdate-waldo#complete',
  GET_STARTED = '/free-trial-sign-up',
  FORGOT_PASSWORD = '/forgot-password',
  FUNNEL_ACTIVE_SUBSCRIPTION = '/funnel-active-subscription',
  NO_SALES = '/no-sales',
  FUNNEL_TRIAL_SELECTOR = '/funnel-trial-selector',
  FUNNEL_PRODUCT_SELECTOR = '/funnel-product-selector',
  FUNNEL_PLAN_SELECTOR = '/funnel-plan-selector',
  FUNNEL_POWER_SELECTOR = '/funnel-power-selector',
  FUNNEL_DOCTOR_SELECTOR = '/funnel-doctor-selector',
  REVIEWS = '/reviews',
  HELP = '/help',
  BLOG = '/blogs/posts',
  PRODUCTS_EYE_STARTER_KIT = '/products/eye-starter-kit',
  PRODUCTS_ORIGINAL = '/products/original-lenses',
  PRODUCTS_HYDRABOOST_PLUS = '/products/hydra-boost-plus',
  PRODUCTS_VITAMIN = '/products/vitamin-lenses',
  PRODUCTS_ORIGINAL_OTP = '/products/original-lenses?view=otp',
  PRODUCTS_VITAMIN_OTP = '/products/vitamin-lenses?view=otp',
  PRODUCTS_BLG_GENDER_SELECTION = '/blue-light-glasses',
  PRODUCTS_BLG_CATALOG = '/collections/blue-light-glasses',
  PRODUCTS_BLG = '/products/blue-light-glasses/',
  PRODUCT_EGIFTCARD = '/products/e-gift-card',
  PRODUCT_HYDRATION_DROPS = '/products/hydration-drops',
  PRIVACY_POLICY = '/privacy-policy',
  COOKIE_POLICY = '/cookie-policy',
  TERMS_SERVICE = '/terms-service',
  FAQS = '/help/faqs',
  HELP_FAQ = '/help/faq-id',
  HELP_FEEDBACK = '/help/faq-feedback',
  HELP_VOTE_RESULTS = '/help/faq-rate',
  PAGE_NOT_FOUND = '/page-not-found',
  ACCOUNT_PAYMENT_DETAILS = '/account/payment-details',
  ACCOUNT_PAYPAL_RETURN_URL = '/account/paypal/complete',
  ACCOUNT_PAYPAL_CANCEL_URL = '/account/paypal/failure',
  CHECKOUT_PAYPAL_RETURN_URL = '/checkout/paypal/complete',
  CHECKOUT_PAYPAL_CANCEL_URL = '/checkout/paypal/failure',
  YOTPO_REWARDS = '/rewards',
  SIGHT_SAVERS = '/sight-savers',
  // MEET_THE_EXPERTS = '/meet-the-experts',
  RESET_PASSWORD = '/forgot-password/',
  INVOICE = '/invoice',
  FIND_A_VISION_CENTER = '/find-vision-center',
  INSTAGRAM = 'https://www.instagram.com/waldo_vision/',
  FACEBOOK = 'https://www.facebook.com/waldovision/',
  TWITTER = 'https://twitter.com/waldo_vision',
}
