export const appCatalogVariantIcon = (
  variantHandle: string
): string | undefined => {
  let icon: string | undefined;

  if (variantHandle.match(/lenses/)) {
    if (variantHandle.match(/vitamin/)) {
      icon = '/images/home/products/hbp-lenses.png';
    } else {
      icon = '/images/products/original-lens/sleeves-Lens2.png';
    }
  }

  if (variantHandle.match(/drops/)) {
    icon = '/images/products/drops/hydration-drops-white-bg.png';
  }

  if (variantHandle.match(/knox|murphy|stacia|nikka/)) {
    switch (variantHandle) {
      case 'knox-crystal':
        icon = '/images/products/blue-light-glasses/plp/knox/crystal/front.png';
        break;
      case 'knox-blue-tortoise-shell':
        icon =
          '/images/products/blue-light-glasses/plp/knox/blue-tortoise-shell/front.png';
        break;
      case 'knox-tortoise-shell':
        icon =
          '/images/products/blue-light-glasses/plp/knox/tortoise-shell/front.png';
        break;
      case 'knox-crystal-tortoise-shell':
        icon =
          '/images/products/blue-light-glasses/plp/knox/crystal-tortoise-shell/front.png';
        break;
      case 'murphy-tortoise-shell':
        icon =
          '/images/products/blue-light-glasses/plp/murphy/tortoise-shell/front.png';
        break;
      case 'murphy-crystal':
        icon =
          '/images/products/blue-light-glasses/plp/murphy/crystal/front.png';
        break;
      case 'murphy-black':
        icon = '/images/products/blue-light-glasses/plp/murphy/black/front.png';
        break;
      case 'murphy-crystal-tortoise-shell':
        icon =
          '/images/products/blue-light-glasses/plp/murphy/crystal-tortoise-shell/front.png';
        break;
      case 'stacia-black':
        icon = '/images/products/blue-light-glasses/plp/stacia/black/front.png';
        break;
      case 'stacia-tortoise-shell':
        icon =
          '/images/products/blue-light-glasses/plp/stacia/tortoise-shell/front.png';
        break;
      case 'stacia-pink-crystal':
        icon =
          '/images/products/blue-light-glasses/plp/stacia/pink-crystal/front.png';
        break;
      case 'stacia-crystal':
        icon =
          '/images/products/blue-light-glasses/plp/stacia/crystal/front.png';
        break;
      case 'nikka-black':
        icon = '/images/products/blue-light-glasses/plp/nikka/black/front.png';
        break;
      case 'nikka-tortoise-shell':
        icon =
          '/images/products/blue-light-glasses/plp/nikka/tortoise-shell/front.png';
        break;
      case 'nikka-crystal':
        icon =
          '/images/products/blue-light-glasses/plp/nikka/crystal/front.png';
        break;
    }
  }

  return icon;
};
