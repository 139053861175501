import { useDispatch, useSelector } from 'react-redux';
import { menuPageSlice, MenuPageState, StoreState } from '../../../redux';

export type PageNavbarMenuType =
  | 'main-menu'
  | 'shop-all'
  | 'about-us'
  | 'for-doctors'
  | false;

export interface PageMenuEffect {
  menu: MenuPageState;
  setNavbarMenu: (navbar: PageNavbarMenuType) => void;
  setAccountMenu: (account: boolean) => void;
  closeAllMenus: () => void;
}

export const usePageMenuEffect = (): PageMenuEffect => {
  const menu = useSelector((state: StoreState) => state.page.menu);

  const dispatch = useDispatch();

  const setNavbarMenu = (navbar: PageNavbarMenuType): void => {
    dispatch(menuPageSlice.actions.update({ navbar, account: menu.account }));
  };

  const setAccountMenu = (account: boolean): void => {
    dispatch(menuPageSlice.actions.update({ navbar: menu.navbar, account }));
  };

  const closeAllMenus = (): void => {
    dispatch(menuPageSlice.actions.update({ navbar: false, account: false }));
  };

  return {
    menu,
    setNavbarMenu,
    setAccountMenu,
    closeAllMenus,
  };
};
