import { appStorage, AppStorageKey } from '../app-storage';
import {
  CatalogPlan,
  CatalogProduct,
  CatalogTrial,
} from '@hiwaldo/sdk/interfaces';

const filePath = '/data/catalog.json';
const revalidatePeriod = 60000;

export interface AppCatalog {
  productHydraBoostDaily: CatalogProduct;
  productHydraBoostTrial: CatalogProduct;
  productVitaminBoostDaily: CatalogProduct;
  productVitaminBoostTrial: CatalogProduct;
  productBlueLightGlasses: CatalogProduct;
  productEGiftCard: CatalogProduct;
  productHydrationDrops: CatalogProduct;
  trialHydraBoost: CatalogTrial;
  trialHydraBoostBundle: CatalogTrial;
  trialVitaminBoost: CatalogTrial;
  trialVitaminBoostBundle: CatalogTrial;
  planHydraBoostMonthly: CatalogPlan;
  planHydraBoostQuarterly: CatalogPlan;
  planHydraBoostBiannual: CatalogPlan;
  planVitaminBoostMonthly: CatalogPlan;
  planVitaminBoostQuarterly: CatalogPlan;
  planVitaminBoostBiannual: CatalogPlan;
}

export const appCatalog = {
  revalidate: false,
  fetch: async (): Promise<AppCatalog> => {
    const storedCatalog = appStorage.get<AppCatalog | undefined>(
      AppStorageKey.STORE_CATALOG,
      undefined
    );

    if (storedCatalog) {
      if (!appCatalog.revalidate) {
        appCatalog.revalidate = true;
        setTimeout(appCatalog.clear, revalidatePeriod);
      }

      return storedCatalog;
    }

    const response = await fetch(filePath);
    const fetchedCatalog = await response.json();

    if (!appCatalog) {
      throw new Error('Unable to fetch store catalog');
    }

    appStorage.set(AppStorageKey.STORE_CATALOG, fetchedCatalog);

    appCatalog.revalidate = true;
    setTimeout(appCatalog.clear, revalidatePeriod);

    return fetchedCatalog;
  },

  clear: (): void => {
    appStorage.remove(AppStorageKey.STORE_CATALOG);
    appCatalog.revalidate = false;
  },
};
