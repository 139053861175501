import { useDispatch, useSelector } from 'react-redux';
import { policyPageSlice, PolicyPageState, StoreState } from '../../../redux';

export interface PagePolicyEffect {
  policy: PolicyPageState;
  setPrivacyPolicy: (accepted: boolean) => void;
  setCookiesPolicy: (accepted: boolean) => void;
  setTermsPolicy: (accepted: boolean) => void;
}

export const usePagePolicyEffect = (): PagePolicyEffect => {
  const dispatch = useDispatch();

  const policy = useSelector((state: StoreState) => state.page.policy);

  const { privacy, cookies, terms } = policy;

  const setPrivacyPolicy = (accepted: boolean): void => {
    dispatch(
      policyPageSlice.actions.update({ cookies, terms, privacy: accepted })
    );
  };

  const setCookiesPolicy = (accepted: boolean): void => {
    dispatch(
      policyPageSlice.actions.update({ cookies: accepted, terms, privacy })
    );
  };

  const setTermsPolicy = (accepted: boolean): void => {
    dispatch(
      policyPageSlice.actions.update({ cookies, terms: accepted, privacy })
    );
  };

  return {
    policy,
    setPrivacyPolicy,
    setCookiesPolicy,
    setTermsPolicy,
  };
};
