import { initializeApollo } from '../../server';
import { Dispatch } from 'react';
import {
  customerSlice,
  customerAccountSlice,
  CustomerDataState,
  cleanCustomerDataState,
  modernVersionBridge,
  OrderDataState,
  cleanOrderDataState,
} from '..';
import {
  customersAuthedCustomerQuery,
  ordersListCustomerOrdersQuery,
} from '../../server';
import { orderListSlice } from '../redux/orders/order';
import { appStorage, AppStorageKey } from '../../helpers';

export const handleDefaultSSE = async (
  dispatch: Dispatch<any>
): Promise<void> => {
  const apolloClient = initializeApollo(null);

  const customerQuery = await customersAuthedCustomerQuery(apolloClient);

  dispatch(customerSlice.actions.authed(customerQuery.data.authedCustomer));

  const customerDataState = appStorage.get(
    AppStorageKey.DATA_CUSTOMER_STATE,
    cleanCustomerDataState
  ) as CustomerDataState;

  modernVersionBridge.customer.update(
    dispatch,
    customerDataState,
    customerQuery.data.authedCustomer
  );

  const ordersQuery = await ordersListCustomerOrdersQuery(
    apolloClient,
    customerQuery.data.authedCustomer
  );

  dispatch(orderListSlice.actions.update(ordersQuery.data.listCustomerOrders));

  const orderDataState = appStorage.get(
    AppStorageKey.DATA_ORDER_STATE,
    cleanOrderDataState
  ) as OrderDataState;

  modernVersionBridge.orders.update(
    dispatch,
    orderDataState,
    ordersQuery.data.listCustomerOrders
  );

  dispatch(
    customerAccountSlice.actions.loadedAccount({
      personal: true,
      delivery: true,
      billing: true,
      payment: true,
      orders: true,
      subscription: {
        plan: true,
        renewal: true,
        prescription: true,
        cost: true,
      },
    })
  );
};
