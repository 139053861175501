import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import * as environment from '../../../environment';
import { currentEnvironment } from '../../../helpers';

export interface LoqateEmailDataItem {
  ResponseCode?: string;
  ResponseMessage?: string;
  EmailAddress?: string;
  UserAccount?: string;
  Domain?: string;
  IsDisposableOrTemporary?: boolean;
  IsComplainerOrFraudRisk?: boolean;
  Duration: number;
}

export interface LoqateEmailDataResponse {
  Items: LoqateEmailDataItem[];
}

export interface CheckEmailValidLoqateResponse {
  response?: LoqateEmailDataItem;
  error?: any;
}

const loqateRequest = (requestUrl: string) => axios.get(requestUrl);
const loqateDebounced = AwesomeDebouncePromise(loqateRequest, 500);

export const loqateValidateEmail = async (
  email: string
): Promise<CheckEmailValidLoqateResponse> => {
  let error = null;
  let dataResponse = null;

  const application = currentEnvironment();

  if (application !== 'prod' && application !== 'uat') {
    return {
      response: { Domain: 'localhost', ResponseCode: 'Valid', Duration: 0 },
      error,
    };
  }

  const loqateKey = environment.envLoqateKeys[application].key;

  const LOQATE_EMAIL_URL =
    'https://api.addressy.com/EmailValidation/Interactive/Validate/v2.00/json3.ws';

  const requestUrl = `${LOQATE_EMAIL_URL}?Key=${loqateKey}&Email=${email}&Timeout=10000`;

  try {
    const response = await loqateDebounced(requestUrl);

    if (response.data) {
      dataResponse = (response.data as LoqateEmailDataResponse).Items[0];
    }
  } catch (errorResponse) {
    error = errorResponse;
  }

  return {
    response: dataResponse ? dataResponse : undefined,
    error,
  };
};
