import { initializeApollo } from '../../server';
import { Dispatch } from 'react';
import { OrderStatusUpdated } from '@hiwaldo/sdk/event/events/order';
import { jobSlice } from '../redux/renewal/job';
import {
  customersAuthedCustomerQuery,
  ordersListCustomerOrdersQuery,
} from '../../server';
import {
  CustomerDataState,
  OrderDataState,
  cleanCustomerDataState,
  cleanOrderDataState,
  customerSlice,
} from '../redux';
import { orderListSlice } from '../redux/orders/order';
import { appStorage, AppStorageKey } from '../../helpers';
import { modernVersionBridge } from '../utils';

export const handleOrderStatusUpdatedSSE = async (
  dispatch: Dispatch<any>,
  event: OrderStatusUpdated
): Promise<void> => {
  if (!event.data.cart?.id) {
    const apolloClient = initializeApollo(null);

    const now = new Date();

    const success = event.data.order.status === 'APPROVED';
    const available = !success;

    dispatch(
      jobSlice.actions.update({
        available,
        success,
        loading: false,
        error: success ? undefined : 'Order failed',
        sent: now.getTime(),
      })
    );

    const customerQuery = await customersAuthedCustomerQuery(apolloClient);

    dispatch(customerSlice.actions.authed(customerQuery.data.authedCustomer));

    const customerDataState = appStorage.get(
      AppStorageKey.DATA_CUSTOMER_STATE,
      cleanCustomerDataState
    ) as CustomerDataState;

    modernVersionBridge.customer.update(
      dispatch,
      customerDataState,
      customerQuery.data.authedCustomer
    );

    const ordersQuery = await ordersListCustomerOrdersQuery(
      apolloClient,
      customerQuery.data.authedCustomer
    );

    dispatch(
      orderListSlice.actions.update(ordersQuery.data.listCustomerOrders)
    );

    const orderDataState = appStorage.get(
      AppStorageKey.DATA_ORDER_STATE,
      cleanOrderDataState
    ) as OrderDataState;

    modernVersionBridge.orders.update(
      dispatch,
      orderDataState,
      ordersQuery.data.listCustomerOrders
    );
  }
};
