import React, { useState, useEffect } from 'react';
import { PageEnvironmentDisplayType } from '../environment';
import { RouteProductLinks, RoutingEffect } from '../routing';
import { currentRegion } from '../../../helpers';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { StoreState, usePageMenuEffect } from '../../../state';
import { useCustomerEffect } from '../customer';
import {
  ButtonInputProps,
  CheckoutUpsellProps,
  DropdownProps,
  DropdownSlot,
  HeaderAccountProps,
  HeaderBannerProps,
  HeaderBasketProps,
  HeaderProductProps,
  HeaderProps,
  HeaderStyleClass,
  HeaderTemplate,
} from '../../../components';
import {
  BasketTrialEffect,
  BasketProductVariantEffect,
  BasketTotalEffect,
  BasketSubscriptionEffect,
  BasketDisplayEffect,
} from '../../basket';
import { SiteFeatureList } from '../../../server';

export interface HeaderBasket {
  basketTrial: BasketTrialEffect;
  basketProductVariant: BasketProductVariantEffect;
  basketTotals: BasketTotalEffect;
  basketSubscription: BasketSubscriptionEffect;
  basketDisplay: BasketDisplayEffect;
  upsell: CheckoutUpsellProps | undefined;
  cartId?: number;
  cartItems?: number;
}

export const usePageHeaderEffect = (props: {
  template: HeaderTemplate;
  styleClasses: HeaderStyleClass[];
  routing: RoutingEffect;
  display: PageEnvironmentDisplayType;
  basket?: HeaderBasket;
  account?: true;
  funnel?: true;
  activeFeatures?: SiteFeatureList;
}): HeaderProps => {
  const router = useRouter();

  const { template, styleClasses, routing, display, basket } = props;

  const { customerDataState } = useSelector((state: StoreState) => ({
    customerDataState: state.data.customer,
  }));

  const { customer } = customerDataState;

  const region = currentRegion();

  const { menu, setNavbarMenu, setAccountMenu, closeAllMenus } =
    usePageMenuEffect();

  const { logout } = useCustomerEffect();

  const [isMounted, setMounted] = useState(false);

  const [isDoctorsOpen, setIsDoctorsOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isRegionOpen, setIsRegionOpen] = useState(false);

  const [isShopAllOpen, setShopAllOpen] = useState(false);
  const openShopAllHandler = () => setShopAllOpen(true);
  const closeShopAllHandler = () => setShopAllOpen(false);

  const openNavSiderbarHandler = () => {
    document.body.classList.add('sidebar-open');
    setNavbarMenu('main-menu');
  };

  const closeNavSiderbarHandler = () => {
    document.body.classList.remove('sidebar-open');
    setNavbarMenu(false);
  };

  const openAccountSiderbarHandler = () => {
    document.body.classList.add('sidebar-open');
    setAccountMenu(true);
  };

  const closeAccountSiderbarHandler = () => {
    document.body.classList.remove('sidebar-open');
    setAccountMenu(false);
  };

  const handleLink = (linkHandler: () => void) => {
    document.body.classList.remove('sidebar-open');
    closeAllMenus();
    linkHandler();
  };

  const productLinks: RouteProductLinks = {
    vitaminBoostLenses: {
      ...routing.productLinks.vitaminBoostLenses,
      onLinkHandler: () =>
        handleLink(routing.productLinks.vitaminBoostLenses.onLinkHandler),
    },
    hydraBoostLenses: {
      ...routing.productLinks.hydraBoostLenses,
      onLinkHandler: () =>
        handleLink(routing.productLinks.hydraBoostLenses.onLinkHandler),
    },
    starterkit:
      region === 'US' && routing.productLinks.starterkit
        ? {
            ...routing.productLinks.starterkit,
            onLinkHandler: () =>
              handleLink(routing.productLinks.starterkit!.onLinkHandler),
          }
        : undefined,
    eyeDrops:
      region === 'US' && routing.productLinks.eyeDrops
        ? {
            ...routing.productLinks.eyeDrops,
            onLinkHandler: () =>
              handleLink(routing.productLinks.eyeDrops!.onLinkHandler),
          }
        : undefined,
    blueLightGlasses:
      ['US', 'UK'].includes(region) && routing.productLinks.blueLightGlasses
        ? {
            ...routing.productLinks.blueLightGlasses,
            onLinkHandler: () =>
              handleLink(routing.productLinks.blueLightGlasses!.onLinkHandler),
          }
        : undefined,
  };

  const doctorsDropdown: DropdownProps | undefined =
    region === 'US' && template === 'headerDefault'
      ? {
          id: 'header-doctors',
          name: 'header-doctors',
          template: 'dropdownDefault',
          styleClasses: ['dropdownBase'],
          label: 'For Doctors',
          isOpen: isDoctorsOpen,
          slots: [
            {
              onClickHandler: () =>
                handleLink(routing.doctorLinks.partner.onLinkHandler),
              label: routing.doctorLinks.partner.label,
            },
            {
              onClickHandler: () =>
                handleLink(routing.doctorLinks.ambassador.onLinkHandler),
              label: routing.doctorLinks.ambassador.label,
            },
          ],
          onClickHandler: () => {
            closeShopAllHandler();
            setIsDoctorsOpen(!isDoctorsOpen);
          },
        }
      : undefined;

  const aboutDropdownSlots: DropdownSlot[] = [];

  aboutDropdownSlots.push({
    onClickHandler: () => handleLink(routing.aboutLinks.ourStory.onLinkHandler),
    label: routing.aboutLinks.ourStory.label,
  });

  aboutDropdownSlots.push({
    onClickHandler: () => handleLink(routing.aboutLinks.ourBlog.onLinkHandler),
    label: routing.aboutLinks.ourBlog.label,
  });

  if (region === 'US') {
    aboutDropdownSlots.push({
      onClickHandler: () =>
        handleLink(routing.aboutLinks.howWorks!.onLinkHandler),
      label: routing.aboutLinks.howWorks.label,
    });
  }

  aboutDropdownSlots.push({
    onClickHandler: () =>
      handleLink(routing.aboutLinks.visionaries.onLinkHandler),
    label: routing.aboutLinks.visionaries.label,
  });

  aboutDropdownSlots.push({
    onClickHandler: () => handleLink(routing.aboutLinks.giftCard.onLinkHandler),
    label: routing.aboutLinks.giftCard.label,
  });

  aboutDropdownSlots.push({
    onClickHandler: () => handleLink(routing.aboutLinks.help.onLinkHandler),
    label: routing.aboutLinks.help.label,
  });

  const aboutDropdown: DropdownProps | undefined =
    template === 'headerDefault'
      ? {
          id: 'header-about',
          name: 'header-about',
          template: 'dropdownDefault',
          styleClasses: ['dropdownBase'],
          label: 'About Us',
          isOpen: isAboutOpen,
          slots: aboutDropdownSlots,
          onClickHandler: () => {
            closeShopAllHandler();
            setIsAboutOpen(!isAboutOpen);
          },
        }
      : undefined;

  const homeLink = {
    ...routing.homeLink,
    onLinkHandler: () => handleLink(routing.homeLink.onLinkHandler),
  };

  const loginLink = {
    ...routing.loginLink,
    onLinkHandler: () => handleLink(routing.loginLink.onLinkHandler),
  };

  const logoutLink = {
    ...routing.logoutLink,
    onLinkHandler: () => {
      document.body.classList.remove('sidebar-open');
      closeAllMenus();
      logout();
    },
  };

  const getStartedLink = {
    ...routing.getStartedLink,
    onLinkHandler: () => handleLink(routing.getStartedLink.onLinkHandler),
  };

  const howItWorksLink = {
    ...routing.howItWorksLink,
    onLinkHandler: () => handleLink(routing.howItWorksLink.onLinkHandler),
  };

  const visionCenterLink =
    region === 'US'
      ? {
          ...routing.visionCenterLink,
          onLinkHandkler: routing.visionCenterLink.onLinkHandler,
        }
      : undefined;

  const aboutLinks = {
    ourStory: {
      ...routing.aboutLinks.ourStory,
      onLinkHandler: () =>
        handleLink(routing.aboutLinks.ourStory.onLinkHandler),
    },
    ourBlog: {
      ...routing.aboutLinks.ourBlog,
      onLinkHandler: () => handleLink(routing.aboutLinks.ourBlog.onLinkHandler),
    },
    howWorks: {
      ...routing.aboutLinks.howWorks,
      onLinkHandler: () =>
        handleLink(routing.aboutLinks.howWorks.onLinkHandler),
    },
    visionaries: {
      ...routing.aboutLinks.visionaries,
      onLinkHandler: () =>
        handleLink(routing.aboutLinks.visionaries.onLinkHandler),
    },
    giftCard: {
      ...routing.aboutLinks.giftCard,
      onLinkHandler: () =>
        handleLink(routing.aboutLinks.giftCard.onLinkHandler),
    },
    help: {
      ...routing.aboutLinks.help,
      onLinkHandler: () => handleLink(routing.aboutLinks.help.onLinkHandler),
    },
  };

  const doctorLinks = {
    partner: {
      ...routing.doctorLinks.partner,
      onLinkHandler: () =>
        handleLink(routing.doctorLinks.partner.onLinkHandler),
    },
    ambassador: {
      ...routing.doctorLinks.ambassador,
      onLinkHandler: () =>
        handleLink(routing.doctorLinks.ambassador.onLinkHandler),
    },
  };

  const usSlot: DropdownSlot = {
    onClickHandler: () => handleLink(routing.regionLinks.us.onLinkHandler),
    label: routing.regionLinks.us.label,
  };

  const ukSlot: DropdownSlot = {
    onClickHandler: () => handleLink(routing.regionLinks.uk.onLinkHandler),
    label: routing.regionLinks.uk.label,
  };

  const seSlot: DropdownSlot = {
    onClickHandler: () => handleLink(routing.regionLinks.se.onLinkHandler),
    label: routing.regionLinks.se.label,
  };

  const euSlot: DropdownSlot = {
    onClickHandler: () => handleLink(routing.regionLinks.eu.onLinkHandler),
    label: routing.regionLinks.eu.label,
  };

  let regionSlots: DropdownSlot[];
  switch (region) {
    case 'UK':
      regionSlots = [usSlot, seSlot, euSlot];
      break;
    case 'SE':
      regionSlots = [usSlot, ukSlot, euSlot];
      break;
    case 'EU':
      regionSlots = [usSlot, ukSlot, seSlot];
      break;
    default:
    case 'US':
      regionSlots = [ukSlot, seSlot, euSlot];
  }

  const regionDropdown: DropdownProps | undefined =
    template === 'headerDefault'
      ? {
          id: 'header-region',
          name: 'header-region',
          template: 'dropdownDefault',
          styleClasses: ['dropdownBase'],
          label: region,
          isOpen: isRegionOpen,
          slots: regionSlots,
          onClickHandler: () => {
            setShopAllOpen(false);
            setIsRegionOpen(!isRegionOpen);
          },
        }
      : undefined;

  const accountLinks = {
    dashboard: {
      ...routing.accountLinks.dashboard,
      onLinkHandler: () =>
        handleLink(routing.accountLinks.dashboard.onLinkHandler),
    },
    plan: {
      ...routing.accountLinks.plan,
      onLinkHandler: () => handleLink(routing.accountLinks.plan.onLinkHandler),
    },
    paymentDetails: {
      ...routing.accountLinks.paymentDetails,
      onLinkHandler: () =>
        handleLink(routing.accountLinks.paymentDetails.onLinkHandler),
    },
    personalDetails: {
      ...routing.accountLinks.personalDetails,
      onLinkHandler: () =>
        handleLink(routing.accountLinks.personalDetails.onLinkHandler),
    },
    shippingAddress: {
      ...routing.accountLinks.shippingAddress,
      onLinkHandler: () =>
        handleLink(routing.accountLinks.shippingAddress.onLinkHandler),
    },
    billingAddress: {
      ...routing.accountLinks.billingAddress,
      onLinkHandler: () =>
        handleLink(routing.accountLinks.billingAddress.onLinkHandler),
    },
    orderHistory: {
      ...routing.accountLinks.orderHistory,
      onLinkHandler: () =>
        handleLink(routing.accountLinks.orderHistory.onLinkHandler),
    },
    logout: {
      ...routing.accountLinks.logout,
      onLinkHandler: () =>
        handleLink(routing.accountLinks.logout.onLinkHandler),
    },
  };

  const account: HeaderAccountProps | undefined =
    template === 'headerDefault'
      ? {
          accountLinks,
          accountOpen: menu.account,
          isAccount: !!props.account,
          isAuthenticated: !!customer,
          isSubscriber: !!customer?.activeCustomerSubscription,
          openAccountSiderbarHandler,
          closeAccountSiderbarHandler,
        }
      : undefined;

  const shopAll: HeaderProductProps | undefined =
    template === 'headerDefault'
      ? {
          label: region === 'US' ? 'Shop All' : 'Products',
          isOpen: isShopAllOpen,
          region,
          productLinksExtended: routing.productLinksExtended,
          openShopAllHandler,
          closeShopAllHandler,
        }
      : undefined;

  const checkoutButtonProps: ButtonInputProps = {
    id: 'header-basket-checkout',
    name: 'header-basket-checkout',
    type: 'button',
    template: 'buttonInputDefault',
    label: 'CHECKOUT',
    styleClasses: ['buttonInputCTA', 'buttonInputCheckout'],
    onClickHandler: () =>
      customer
        ? routing.checkoutLinks.review.onLinkHandler()
        : routing.checkoutLinks.register.onLinkHandler(),
  };

  const basketProps: HeaderBasketProps | undefined =
    template === 'headerDefault' && basket
      ? {
          label: 'Basket',
          homeLink: routing.homeLink,
          checkoutButtonProps,
          checkoutUpsellProps: basket.upsell || undefined,
          basketTrial: basket.basketTrial,
          basketProductVariant: basket.basketProductVariant,
          basketTotals: basket.basketTotals,
          basketDisplay: basket.basketDisplay,
          cartId: basket.cartId,
          cartItems: basket.cartItems || 0,
        }
      : undefined;

  const getStartedProps: ButtonInputProps = {
    id: 'header-get-started',
    name: 'header-get-started',
    type: 'button',
    template: 'buttonInputDefault',
    label: display === 'mobile' ? 'START' : 'GET STARTED',
    styleClasses: ['buttonInputCTA', 'buttonInputCheckout'],
    onClickHandler: () => routing.getStartedLink.onLinkHandler(),
  };

  const bannerProps: HeaderBannerProps | undefined =
    region === 'US'
      ? undefined
      : {
          line1: (
            <>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  handleLink(routing.noSalesLink.onLinkHandler);
                }}
              >
                For operational reasons, we're unable to accept new orders at
                this time. Existing customers can access their accounts as usual
              </span>{' '}
              <span
                className="link-span"
                onClick={(e) => {
                  e.preventDefault();
                  handleLink(routing.accountLink.onLinkHandler);
                }}
              >
                here
              </span>
              .
            </>
          ),
        };

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);

      const handleRouteChange = () => {
        document.body.classList.remove('sidebar-open');
        closeAllMenus();
      };

      router.events.on('routeChangeStart', handleRouteChange);

      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    }
  }, [router.events, isMounted]);

  return {
    id: 'waldo-header',
    customer,
    template,
    display,
    isNavSidebarOpen: !!menu.navbar,
    isAccountSidebarOpen: menu.account,
    styleClasses: bannerProps
      ? [...styleClasses, 'headerBanner']
      : styleClasses,
    homeLink,
    loginLink,
    logoutLink,
    getStartedLink: region === 'US' ? getStartedLink : undefined,
    visionCenterLink,
    howItWorksLink: region !== 'US' ? undefined : howItWorksLink,
    getStartedProps:
      props.funnel || region !== 'US' ? undefined : getStartedProps,
    doctorsDropdown,
    aboutDropdown,
    regionDropdown,
    productLinks,
    aboutLinks,
    doctorLinks,
    account,
    shopAll,
    basket: basketProps,
    currentMobileMenu: menu.navbar ? menu.navbar : 'main-menu',
    currentRegion: region,
    banner: bannerProps,
    setMobileMenu: setNavbarMenu,
    openNavSiderbarHandler,
    closeNavSiderbarHandler,
    openAccountSiderbarHandler,
    closeAccountSiderbarHandler,
  };
};
