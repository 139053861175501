import { Dispatch } from 'react';
import { appStorage, AppStorageKey } from '../../helpers';
import { AccountDataState, cleanAccountDataState, accountDataSlice } from '..';

export const handleCustomerPrescriptionUpdatedSSE = async (
  dispatch: Dispatch<any>
): Promise<void> => {
  const accountDataState = appStorage.get(
    AppStorageKey.DATA_ACCOUNT_STATE,
    cleanAccountDataState
  ) as AccountDataState;

  dispatch(
    accountDataSlice.actions.update({
      ...accountDataState,
      prescriptionUpdated: true,
    })
  );
};
