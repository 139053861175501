import { Dispatch } from 'react';
import { gtmPageSlice } from '../../redux';
import { Currency } from '@hiwaldo/sdk';
import { RevrollPurchaseProductsInterface } from '../../interfaces/page/revroll';
import { Customer } from '@hiwaldo/sdk/interfaces';
import { RevrollEvent } from '../../enums/revroll-event.enum';
import { calculateGtmOrderItems } from '../../utils';
import { OrderCreated } from '@hiwaldo/sdk/event/events/order';
import { AppCatalog } from '../../../helpers';

export const revrollPurchase = (
  dispatch: Dispatch<any>,
  event: OrderCreated,
  customer: Customer,
  data: AppCatalog | undefined
) => {
  const products: RevrollPurchaseProductsInterface[] = [] as any;
  const orderProducts = calculateGtmOrderItems(data, event);

  for (let i = 0; i < orderProducts.items.length; i++) {
    const items = {} as RevrollPurchaseProductsInterface;
    items.name = orderProducts.items[i].item_name;
    items.id = orderProducts.items[i].item_id;
    items.sku = orderProducts.items[i].sku || '';
    items.variant_id = orderProducts.items[i].item_variant;
    items.price = orderProducts.items[i].price || 0;
    items.brand = 'Waldo Store';
    items.category = '';
    items.image = '';
    items.quantity = orderProducts.items[i].quantity || 0;

    products.push(items);
  }

  dispatch(
    gtmPageSlice.actions.revrollUpdate({
      event: RevrollEvent.REVROLL_PURCHASE,
      rr_cart_id: event.data.cart?.id,
      first_name: customer.firstName || '',
      last_name: customer.lastName || '',
      email_address: customer.email || '',
      phone_number: customer.shippingAddress?.phone || '',
      customer_id: customer.id.toString(),
      country: customer.shippingAddress?.country || '',
      region: customer.region,
      city: customer.shippingAddress?.city || '',
      zip: customer.shippingAddress?.postalCode || '',
      ecommerce: {
        currencyCode: Currency.USD,
        purchase: {
          actionField: {
            id: `${event.data.order.id}`,
            affiliation: 'Waldo Store',
            revenue: event.data.order.total_price || 0,
            tax: event.data.order.total_tax_price || 0,
            shipping: event.data.order.shipping_price || 0,
            coupon: event.data.order.discountCode || '',
            discount_amount: event.data.order.discountDeductions || 0,
          },
          products,
        },
      },
    })
  );
};
