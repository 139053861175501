export const TrialVitaminBoostBundleData = {
  id: 4,
  title: 'Daily Eyecare Plus Duo',
  handle: 'vitamin-trial-bundle',
  plans: [
    {
      plan: {
        id: 16,
        planFamily: {
          title: 'Quarterly',
          handle: 'quarterly',
        },
        title: 'Vitamin',
        handle: 'vitamin-x-3',
        products: [
          {
            locales: [
              {
                quantity: 3,
                basePrice: 2250,
                actualPrice: 2100,
              },
              {
                quantity: 3,
                basePrice: 1600,
                actualPrice: 1360,
              },
              {
                quantity: 3,
                basePrice: 24500,
                actualPrice: 16575,
              },
              {
                quantity: 3,
                basePrice: 1900,
                actualPrice: 1615,
              },
            ],
            product: {
              title: 'Vitamin Lenses',
              handle: 'vitamin-lenses',
              description:
                'Help your eyes relax with our ultra-comfortable vitamin lenses.',
              primaryVariant: {
                assets: [
                  {
                    asset: {
                      path: '/images/products/vitamin-lens/2-Vitamin.png',
                    },
                  },
                ],
              },
            },
          },
        ],
        locales: [
          {
            basePrice: 13500,
            actualPrice: 12600,
            sku: 'quarterly-vitamin',
            region: 'US',
          },
          {
            basePrice: 9600,
            actualPrice: 8160,
            sku: 'quarterly-vitamin',
            region: 'UK',
          },
          {
            basePrice: 147000,
            actualPrice: 99450,
            sku: 'quarterly-vitamin',
            region: 'SE',
          },
          {
            basePrice: 11400,
            actualPrice: 9690,
            sku: 'quarterly-vitamin',
            region: 'EU',
          },
        ],
      },
    },
  ],
  products: [],
  bundles: [
    {
      quantity: 1,
      bundle: {
        title: 'Daily Eyecare Plus Duo',
        handle: 'vitamin-trial-bundle',
        products: [
          {
            quantity: 1,
            product: {
              title: 'Vitamin Lens Trial',
              handle: 'trial-vitamin-lenses',
              description:
                'Help your eyes relax with our ultra-comfortable vitamin lenses.',
              primaryVariant: {
                title: 'Vitamin Lens Trial',
                handle: 'trial-vitamin-lenses-negative-0-50',
                assets: [
                  {
                    asset: {
                      path: '/images/products/vitamin-lens/2-Vitamin.png',
                    },
                  },
                ],
              },
            },
          },
          {
            quantity: 1,
            product: {
              title: 'Hydration Drops',
              handle: 'hydration-drops',
              description:
                'Maximum hydration for your hard-working eyes, formulated with 100% natural active ingredients that support your eye’s own ability to heal itself.',
              primaryVariant: {
                title: 'Hydration Drops',
                handle: 'hydration-drops',
                assets: [
                  {
                    asset: {
                      path: '/images/products/drops/hydration-drops-white-bg.png',
                    },
                  },
                ],
              },
            },
          },
          {
            quantity: 1,
            product: {
              title: 'Waldo Tote Bag',
              handle: 'tote-bag',
              description: 'Waldo Tote Bag.',
              primaryVariant: {
                title: 'Waldo Tote Bag',
                handle: 'tote-bag',
                assets: [],
              },
            },
          },
        ],
      },
    },
  ],
  locales: [
    {
      leadTime: 12,
      sku: 'vitamin-trial-bundle',
      region: 'US',
      pricing: [
        {
          actualPrice: 900,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'vitamin-trial-bundle',
      region: 'UK',
      pricing: [
        {
          actualPrice: 900,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'vitamin-trial-bundle',
      region: 'SE',
      pricing: [
        {
          actualPrice: 9000,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'vitamin-trial-bundle',
      region: 'EU',
      pricing: [
        {
          actualPrice: 900,
          basePrice: 0,
        },
      ],
    },
  ],
};
