export const envHostnames = {
  domain: {
    prod: {
      US: 'https://www.hiwaldo.com',
      UK: 'https://uk.hiwaldo.com',
      EU: 'https://eu.hiwaldo.com',
      SE: 'https://se.hiwaldo.com',
      DOCTOR: 'https://doctors.hiwaldo.com',
    },
    uat: {
      US: 'https://uat-us.hiwaldo.com',
      UK: 'https://uat-uk.hiwaldo.com',
      EU: 'https://uat-eu.hiwaldo.com',
      SE: 'https://uat-se.hiwaldo.com',
      DOCTOR: 'https://uat-doctors.hiwaldo.com',
    },
    staging: {
      US: 'https://staging-v2-us.hiwaldo.com',
      UK: 'https://staging-v2-uk.hiwaldo.com',
      EU: 'https://staging-v2-eu.hiwaldo.com',
      SE: 'https://staging-v2-se.hiwaldo.com',
      DOCTOR: 'https://staging-doctors.hiwaldo.com',
    },
    local: {
      US: 'http://localwaldo.com',
      UK: 'http://uk.localwaldo.com',
      EU: 'http://eu.localwaldo.com',
      SE: 'http://se.localwaldo.com',
      DOCTOR: 'https://localwaldodoctor.com',
    },
  },
  host: {
    prod: {
      US: 'hiwaldo.com',
      UK: 'uk.hiwaldo.com',
      EU: 'eu.hiwaldo.com',
      SE: 'se.hiwaldo.com',
      DOCTOR: 'doctors.hiwaldo.com',
    },
    uat: {
      US: 'uat-us.hiwaldo.com',
      UK: 'uat-uk.hiwaldo.com',
      EU: 'uat-eu.hiwaldo.com',
      SE: 'uat-se.hiwaldo.com',
      DOCTOR: 'uat-doctors.hiwaldo.com',
    },
    staging: {
      US: 'staging-v2-us.hiwaldo.com',
      UK: 'staging-v2-uk.hiwaldo.com',
      EU: 'staging-v2-eu.hiwaldo.com',
      SE: 'staging-v2-se.hiwaldo.com',
      DOCTOR: 'staging-doctors.hiwaldo.com',
    },
    local: {
      US: 'localwaldo.com',
      UK: 'uk.localwaldo.com',
      EU: 'eu.localwaldo.com',
      SE: 'se.localwaldo.com',
      DOCTOR: 'localwaldodoctor.com',
    },
  },
};
