import { initializeApollo } from '../../server';
import { Dispatch } from 'react';
import { OrderCreated } from '@hiwaldo/sdk/event/events/order';
import { orderListSlice } from '../redux/orders/order';
import { AppCatalog, appStorage, AppStorageKey } from '../../helpers';
import { OrderPaymentGateway } from '@hiwaldo/sdk';
import {
  customersAuthedCustomerQuery,
  ordersListCustomerOrdersQuery,
} from '../../server/queries';
import {
  gtmCheckoutConversion,
  gtmCheckoutPaymentInfo,
  gtmCheckoutPurchase,
  revrollPurchase,
  modernVersionBridge,
} from '../utils';
import {
  CartState,
  customerSlice,
  CustomerState,
  FunnelState,
  BasketDataState,
  cleanBasketDataState,
  OrderDataState,
  orderDataSlice,
  CustomerDataState,
  cleanCustomerDataState,
  cleanOrderDataState,
  basketDataSlice,
} from '..';

const filePath = '/data/catalog.json';

export const handleOrderCreatedSSE = async (
  dispatch: Dispatch<any>,
  event: OrderCreated
): Promise<void> => {
  const apolloClient = initializeApollo(null);

  const customerQuery = await customersAuthedCustomerQuery(apolloClient);

  dispatch(customerSlice.actions.authed(customerQuery.data.authedCustomer));

  const customerDataState = appStorage.get(
    AppStorageKey.DATA_CUSTOMER_STATE,
    cleanCustomerDataState
  ) as CustomerDataState;

  modernVersionBridge.customer.update(
    dispatch,
    customerDataState,
    customerQuery.data.authedCustomer
  );

  const ordersQuery = await ordersListCustomerOrdersQuery(
    apolloClient,
    customerQuery.data.authedCustomer
  );

  const orders = ordersQuery.data.listCustomerOrders;

  dispatch(orderListSlice.actions.update(orders));

  const orderDataState = appStorage.get(
    AppStorageKey.DATA_ORDER_STATE,
    cleanOrderDataState
  ) as OrderDataState;

  modernVersionBridge.orders.update(dispatch, orderDataState, orders);

  const customerState = appStorage.get(
    AppStorageKey.CUSTOMER_STATE,
    undefined,
    false
  ) as CustomerState | undefined;

  const cartState = appStorage.get(
    AppStorageKey.PAID_CART,
    undefined,
    false
  ) as CartState | undefined;

  appStorage.remove(AppStorageKey.PAID_CART);

  const funnelState = appStorage.get(
    AppStorageKey.COMPLETED_FUNNEL,
    undefined,
    false
  ) as FunnelState | undefined;

  appStorage.remove(AppStorageKey.COMPLETED_FUNNEL);

  if (event.data.cart?.id && cartState?.cart && customerState) {
    const response = await fetch(filePath);
    const fetchedCatalog = response
      ? ((await response.json()) as AppCatalog)
      : undefined;

    const gateway = event.data.order.payment?.gateway as OrderPaymentGateway;

    if (gateway) {
      gtmCheckoutPaymentInfo(dispatch, event, gateway, fetchedCatalog);
      gtmCheckoutPurchase(dispatch, event, fetchedCatalog);
    }

    gtmCheckoutConversion(
      dispatch,
      event.data.region,
      cartState,
      customerState,
      funnelState
    );

    revrollPurchase(
      dispatch,
      event,
      customerQuery.data.authedCustomer,
      fetchedCatalog
    );

    const basketDataState = appStorage.get(
      AppStorageKey.DATA_BASKET_STATE,
      cleanBasketDataState
    ) as BasketDataState;

    const orderDataState: OrderDataState = Object.assign({}, {
      orders: orders.data,
      countOrders: orders.rows,
      latestOrder: {
        countItems: basketDataState.countItems,
        countProductVariants: basketDataState.countProductVariants,
        countBundles: basketDataState.countBundles,
        countTrials: basketDataState.countTrials,
        includesLenses: basketDataState.includesLenses,
        includesTrial: basketDataState.includesTrial,
        includesBundle: basketDataState.includesBundle,
        includesBothEyes: basketDataState.includesBothEyes,
        includesDuplicateEyes: basketDataState.includesDuplicateEyes,
        includesBLG: basketDataState.includesBLG,
        includesDrops: basketDataState.includesDrops,
        includesDoctor: basketDataState.includesDoctor,
        includesPrescription: basketDataState.includesPrescription,
        includesTax: basketDataState.includesTax,
        renewalForecast: basketDataState.renewalForecast,
        leftEye: basketDataState.leftEye,
        rightEye: basketDataState.rightEye,
        order: orders.data[0],
        cart: basketDataState.cart,
      },
    } as OrderDataState);

    dispatch(orderDataSlice.actions.update(orderDataState));
    dispatch(basketDataSlice.actions.update(cleanBasketDataState));
  }
};
