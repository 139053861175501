import { Region } from '@hiwaldo/sdk';
import { Dispatch } from 'react';
import { GTMPageEvent } from '../../enums';
import {
  CartState,
  CustomerState,
  FunnelState,
  gtmPageSlice,
} from '../../redux';

export interface GTMCheckoutConversionData {
  customer_email?: string;
  customer_first_name?: string;
  customer_last_name?: string;
  customer_address1?: string;
  customer_address2?: string;
  customer_company?: string;
  customer_city?: string;
  customer_zip?: string;
  customer_state?: string;
  customer_country?: string;
  customer_type?: 'NEW' | 'EXISTING';
  left_eye_power?: string;
  right_eye_power?: string;
  payment_method?: string;
  order_id?: number;
  order_name?: string;
  order_type?:
    | 'Hydra Boost trial'
    | 'Hydra Boost Starter Kit'
    | 'Hydra Boost Plus trial'
    | 'Hydra Boost Plus Starter Kit'
    | 'Hydra Boost OTP lenses order'
    | 'Hydra Boost Plus OTP lenses order'
    | 'Hydration Drops order'
    | 'Blue Light Glasses order'
    | 'E Gift Card order';
  order_total?: number;
  order_tax?: number;
  order_subtotal?: number;
  order_timestamp?: number;
  order_currency?: string;
  order_discount_code?: string;
  order_discount_amount?: number;
  order_num_items?: number;
  order_item_names?: string[];
  order_item_ids?: number[];
  order_item_categories?: string[];
  order_item_contents?: {
    id?: number;
    quantity?: number;
    item_price?: string;
  }[];
  order_line_items?: {
    product_name?: string;
    product_id?: number;
    product_quantity?: number;
    product_price?: string;
  }[];
  order_klaviyo_items?: {
    SKU?: string;
    ProductName?: string;
    Quantity?: number;
    ItemPrice?: string;
    RowTotal?: number;
    ProductURL?: string;
    ProductCategories?: string[];
    ProductTitle?: string;
    VariantTitle?: string;
  }[];
  ecommerce?: {
    currency_code?: 'GBP' | 'USD' | 'EUR' | 'SEK';
    purchase?: {
      actionField?: {
        id?: string;
        affiliation?: string;
        revenue?: number;
        tax?: number;
        shipping?: number;
        coupon?: string;
      };
      products?: {
        id?: number;
        name?: string;
        price?: string;
        quantity?: number;
        brand?: string;
        category?: string;
        variant?: string;
      }[];
    };
  };
}

export const gtmCheckoutConversion = (
  dispatch: Dispatch<any>,
  region: Region,
  cartState: CartState,
  customerState: CustomerState,
  funnelState?: FunnelState
) => {
  if (cartState.cart && customerState.customer) {
    const { cart } = cartState;
    const { customer } = customerState;

    const productType = funnelState?.productType || 'otp';
    const productLensType = funnelState?.productLensType || undefined;
    const productTrialType = funnelState?.productTrialType || undefined;

    let orderType:
      | 'Hydra Boost trial'
      | 'Hydra Boost Starter Kit'
      | 'Hydra Boost Plus trial'
      | 'Hydra Boost Plus Starter Kit'
      | 'Hydra Boost OTP lenses order'
      | 'Hydra Boost Plus OTP lenses order'
      | 'Hydration Drops order'
      | 'Blue Light Glasses order'
      | 'E Gift Card order'
      | undefined;

    if (productType === 'otp') {
      if (productLensType) {
        orderType = productLensType?.includes('vitamin')
          ? 'Hydra Boost Plus OTP lenses order'
          : 'Hydra Boost OTP lenses order';
      } else if (cart.productVariants.length) {
        cart.productVariants.forEach((variant) => {
          if (variant.productVariant.handle.match(/eGift/) && !orderType) {
            orderType = 'E Gift Card order';
          } else if (
            variant.productVariant.handle.match(/drops/) &&
            orderType !== 'Blue Light Glasses order'
          ) {
            orderType = 'Hydration Drops order';
          } else if (
            variant.productVariant.handle.match(/knox|murphy|stacia|nikka/)
          ) {
            orderType = 'Blue Light Glasses order';
          }
        });
      }
    } else if (productType === 'trial') {
      if (productTrialType?.includes('vitamin')) {
        orderType = productTrialType?.includes('bundle')
          ? 'Hydra Boost Plus Starter Kit'
          : 'Hydra Boost Plus trial';
      } else {
        orderType = productTrialType?.includes('kit')
          ? 'Hydra Boost Starter Kit'
          : 'Hydra Boost trial';
      }
    }

    const leftEyePower =
      cart!.prescription?.leftEyeProductVariant?.attributes[0].attribute
        .value || undefined;

    const rightEyePower =
      cart!.prescription?.rightEyeProductVariant?.attributes[0].attribute
        .value || undefined;

    let orderNumItems = 0;
    const orderItemNames: string[] = [];
    const orderItemIds: number[] = [];
    const orderItemCategories: string[] = [];

    const orderItemContents: {
      id?: number;
      quantity?: number;
      item_price?: string;
    }[] = [];

    const orderLineItems: {
      product_name?: string;
      product_id?: number;
      product_quantity?: number;
      product_price?: string;
    }[] = [];

    const orderKlaviyoItems: {
      SKU?: string;
      ProductName?: string;
      Quantity?: number;
      ItemPrice?: string;
      RowTotal?: number;
      ProductURL?: string;
      ProductCategories?: string[];
      ProductTitle?: string;
      VariantTitle?: string;
    }[] = [];

    const ecommerceProducts: {
      id?: number;
      name?: string;
      price?: string;
      quantity?: number;
      brand?: string;
      category?: string;
      variant?: string;
    }[] = [];

    if (cart.trials?.length && cart.prescription) {
      const cartTrial = cart.trials[0];
      const cartPrescription = cart.prescription;
      let trialLensType: 'hydra-boost' | 'vitamin-boost' = 'hydra-boost';
      let trialBundle = false;

      if (cartPrescription.leftEyeProductVariant) {
        const leftEyeLocale =
          cartPrescription.leftEyeProductVariant.locales.find(
            (leftEyeVariantLocale) => leftEyeVariantLocale.region === region
          );

        trialLensType = leftEyeLocale!.sku?.includes('VIT')
          ? 'vitamin-boost'
          : 'hydra-boost';

        const leftEyeQuantity =
          cartPrescription.rightEyeProductVariant &&
          cartPrescription.rightEyeProductVariant.id ===
            cartPrescription.leftEyeProductVariant.id
            ? 2
            : 1;

        orderNumItems += leftEyeQuantity;
        orderItemNames.push(cartPrescription.leftEyeProductVariant.title);
        orderItemIds.push(+cartPrescription.leftEyeProductVariant.id);

        orderItemContents.push({
          id: +cartPrescription.leftEyeProductVariant.id,
          quantity: leftEyeQuantity,
          item_price: '0',
        });

        orderLineItems.push({
          product_name: cartPrescription.leftEyeProductVariant.title,
          product_id: cartPrescription.leftEyeProductVariant.id,
          product_quantity: leftEyeQuantity,
          product_price: '0',
        });

        orderKlaviyoItems.push({
          SKU: leftEyeLocale!.sku
            ?.replace('BASE', 'FIRST')
            .replace('VIT', 'VITFIRST'),
          ProductName: cartPrescription.leftEyeProductVariant.title,
          Quantity: leftEyeQuantity,
          ItemPrice: '0',
          RowTotal: 0,
          ProductTitle: cartPrescription.leftEyeProductVariant.title,
          VariantTitle: cartPrescription.leftEyeProductVariant.title,
        });

        ecommerceProducts.push({
          id: +cartPrescription.leftEyeProductVariant.id,
          name: cartPrescription.leftEyeProductVariant.title,
          price: '0',
          quantity: leftEyeQuantity,
          brand: 'Waldo',
          variant: cartPrescription.leftEyeProductVariant.title,
        });
      }

      if (
        cartPrescription.rightEyeProductVariant &&
        cartPrescription.rightEyeProductVariant.id !==
          (cartPrescription.leftEyeProductVariant?.id || 0)
      ) {
        const rightEyeLocale =
          cartPrescription.rightEyeProductVariant.locales.find(
            (rightEyeVariantLocale) => rightEyeVariantLocale.region === region
          );

        trialLensType = rightEyeLocale!.sku?.includes('VIT')
          ? 'vitamin-boost'
          : 'hydra-boost';

        const rightEyeQuantity = 1;

        orderNumItems += rightEyeQuantity;
        orderItemNames.push(cartPrescription.rightEyeProductVariant.title);
        orderItemIds.push(+cartPrescription.rightEyeProductVariant.id);

        orderItemContents.push({
          id: +cartPrescription.rightEyeProductVariant.id,
          quantity: rightEyeQuantity,
          item_price: '0',
        });

        orderLineItems.push({
          product_name: cartPrescription.rightEyeProductVariant.title,
          product_id: cartPrescription.rightEyeProductVariant.id,
          product_quantity: rightEyeQuantity,
          product_price: '0',
        });

        orderKlaviyoItems.push({
          SKU: rightEyeLocale!.sku
            ?.replace('BASE', 'FIRST')
            .replace('VIT', 'VITFIRST'),
          ProductName: cartPrescription.rightEyeProductVariant.title,
          Quantity: rightEyeQuantity,
          ItemPrice: '0',
          RowTotal: 0,
          ProductTitle: cartPrescription.rightEyeProductVariant.title,
          VariantTitle: cartPrescription.rightEyeProductVariant.title,
        });

        ecommerceProducts.push({
          id: +cartPrescription.rightEyeProductVariant.id,
          name: cartPrescription.rightEyeProductVariant.title,
          price: '0',
          quantity: rightEyeQuantity,
          brand: 'Waldo',
          variant: cartPrescription.rightEyeProductVariant.title,
        });
      }

      if (cartTrial.trial?.bundles?.length) {
        trialBundle = true;

        const bundleProducts = cartTrial.trial.bundles[0].bundle.products;

        for (const i in bundleProducts) {
          const bundleProduct = bundleProducts[i];

          if (
            bundleProduct.product.handle &&
            !bundleProduct.product.handle.includes('lenses')
          ) {
            orderNumItems += 1;
            orderItemNames.push(bundleProduct.product.title);
            orderItemIds.push(+bundleProduct.productId);

            orderItemContents.push({
              id: +bundleProduct.productId,
              quantity: 1,
              item_price: '0',
            });

            orderLineItems.push({
              product_name: bundleProduct.product.title,
              product_id: bundleProduct.productId,
              product_quantity: 1,
              product_price: '0',
            });

            orderKlaviyoItems.push({
              SKU: '', // bundleProduct.sku,
              ProductName: bundleProduct.product.title,
              Quantity: 1,
              ItemPrice: '0',
              RowTotal: 0,
              ProductTitle: bundleProduct.product.title,
              VariantTitle: bundleProduct.product.title,
            });

            ecommerceProducts.push({
              id: +bundleProduct.productId,
              name: bundleProduct.product.title,
              price: '0',
              quantity: 1,
              brand: 'Waldo',
              variant: bundleProduct.product.title,
            });
          }
        }
      }

      if (!orderType || typeof orderType === 'undefined') {
        orderType =
          trialLensType === 'vitamin-boost'
            ? trialBundle
              ? 'Hydra Boost Plus Starter Kit'
              : 'Hydra Boost Plus trial'
            : trialBundle
            ? 'Hydra Boost Starter Kit'
            : 'Hydra Boost trial';
      }
    }

    if (cart.productVariants?.length) {
      let includesDrops = false;
      let includesBLG = false;
      let includesGiftCard = false;

      for (const i in cart.productVariants) {
        const cartProductVariant = cart.productVariants[i];

        if (cartProductVariant.productVariant.handle) {
          if (cartProductVariant.productVariant.handle.match(/drops/g)) {
            includesDrops = true;
          }

          if (
            cartProductVariant.productVariant.handle.match(
              /knox|murphy|nikka|stacia/g
            )
          ) {
            includesBLG = true;
          }

          if (cartProductVariant.productVariant.handle.match(/egift/g)) {
            includesGiftCard = true;
          }

          orderNumItems += cartProductVariant.quantity;
          orderItemNames.push(cartProductVariant.productVariant.title);
          orderItemIds.push(+cartProductVariant.productVariantId);

          orderItemContents.push({
            id: +cartProductVariant.productVariantId,
            quantity: +cartProductVariant.quantity,
            item_price: `${cartProductVariant.itemPrice / 100}`,
          });

          orderLineItems.push({
            product_name: cartProductVariant.productVariant.title,
            product_id: cartProductVariant.productVariantId,
            product_quantity: cartProductVariant.quantity,
            product_price: `${cartProductVariant.itemPrice / 100}`,
          });

          orderKlaviyoItems.push({
            SKU: '', // cartProductVariant.sku,
            ProductName: cartProductVariant.productVariant.title,
            Quantity: cartProductVariant.quantity,
            ItemPrice: `${cartProductVariant.itemPrice / 100}`,
            RowTotal: cartProductVariant.linePrice / 100,
            ProductTitle: cartProductVariant.productVariant.title,
            VariantTitle: cartProductVariant.productVariant.title,
          });

          ecommerceProducts.push({
            id: +cartProductVariant.productVariantId,
            name: cartProductVariant.productVariant.title,
            price: `${cartProductVariant.itemPrice / 100}`,
            quantity: +cartProductVariant.quantity,
            brand: 'Waldo',
            variant: cartProductVariant.productVariant.title,
          });
        }
      }

      if (!orderType || typeof orderType === 'undefined') {
        orderType = includesDrops
          ? 'Hydration Drops order'
          : includesBLG
          ? 'Blue Light Glasses order'
          : includesGiftCard
          ? 'E Gift Card order'
          : undefined;
      }
    }

    let orderCurrency: 'GBP' | 'USD' | 'EUR' | 'SEK';
    switch (region) {
      case Region.UK:
        orderCurrency = 'GBP';
        break;
      case Region.EU:
        orderCurrency = 'EUR';
        break;
      case Region.SE:
        orderCurrency = 'SEK';
        break;
      default:
        orderCurrency = 'USD';
    }

    const data: GTMCheckoutConversionData = {
      customer_email: customer.email!,
      customer_first_name: customer.firstName!,
      customer_last_name: customer.lastName!,
      customer_address1: customer.shippingAddress!.line1,
      customer_address2: customer.shippingAddress!.line2 || '',
      customer_company: customer.shippingAddress!.company || '',
      customer_city: customer.shippingAddress!.city,
      customer_zip: customer.shippingAddress!.postalCode,
      customer_state: customer.shippingAddress!.state,
      customer_country: customer.shippingAddress!.country,
      customer_type: customerState.registered ? 'NEW' : 'EXISTING',
      left_eye_power: leftEyePower || '',
      right_eye_power: rightEyePower || '',
      payment_method: customer.defaultPaymentMethod?.brand || '',
      order_id: cart.id,
      order_name: '',
      order_type: orderType,
      order_total: +(cart.grandTotalPrice || 0) / 100,
      order_tax: +(cart.taxPrice || 0) / 100,
      order_subtotal: +(cart.subtotalPrice || 0) / 100,
      order_timestamp: Date.now(),
      order_currency: orderCurrency,
      order_discount_code: cart.discounts?.length
        ? cart.discounts[0].discountCode
        : '',
      order_discount_amount: +(cart.discountDeduction || 0) / 100,
      order_num_items: orderNumItems,
      order_item_names: orderItemNames,
      order_item_ids: orderItemIds,
      order_item_categories: orderItemCategories,
      order_item_contents: orderItemContents,
      order_line_items: orderLineItems,
      order_klaviyo_items: orderKlaviyoItems,
      ecommerce: {
        currency_code: orderCurrency,
        purchase: {
          actionField: {
            id: `${cart.id}`,
            affiliation: '',
            revenue: +(cart.grandTotalPrice || 0) / 100,
            tax: +(cart.taxPrice || 0) / 100,
            shipping: cart.shippingOption
              ? +(cart.shippingOption.shippingPrice || 0) / 100
              : 0,
            coupon: cart.discounts?.length
              ? cart.discounts[0].discountCode
              : '',
          },
          products: ecommerceProducts,
        },
      },
    };

    dispatch(
      gtmPageSlice.actions.update({
        event: GTMPageEvent.WALDO_CHECKOUT_CONVERSION,
        ...data,
      })
    );
  }
};
