import useSWR from 'swr';
import {
  CatalogProductVariant,
  CatalogProductVariantAttributeList,
} from '@hiwaldo/sdk/interfaces';
import {
  AppCatalog,
  StoreCatalogPowerVariants,
  StoreCatalogRegionalProductVariant,
  StoreCatalogRegionalPlans,
  StoreCatalogRegionalTrial,
  appCatalog,
  appCatalogPowerVariants,
  appCatalogRegionalProductVariant,
  appCatalogRegionalPlans,
  appCatalogRegionalTrial,
  appCatalogVariantDetails,
  appCatalogVariantIcon,
  appCatalogOTPVariant,
  appCatalogTrialVariant,
  StoreCatalogRegionalProduct,
  appCatalogRegionalProduct,
} from '../../../helpers';

export interface StoreCatalogEffect {
  catalog: AppCatalog | undefined;
  loading: boolean;
  error?: any | undefined;
  getRegionalPlans: () => StoreCatalogRegionalPlans | undefined;
  getRegionalTrial: (
    trialId: 1 | 2 | 3 | 4,
    planId: 15 | 16 | undefined
  ) => StoreCatalogRegionalTrial | undefined;
  getRegionalProduct: (
    productId: number
  ) => StoreCatalogRegionalProduct | undefined;
  getRegionalProductVariant: (
    variantId: number
  ) => StoreCatalogRegionalProductVariant | undefined;
  getPowerVariants: (power: string) => StoreCatalogPowerVariants;
  getTrialVariant: (
    variantId: number,
    productType?: 'daily' | 'vitamin'
  ) => CatalogProductVariant | undefined;
  getOTPVariant: (
    variantId: number,
    productType?: 'daily' | 'vitamin'
  ) => CatalogProductVariant | undefined;
  getVariantDetails: (
    variantId: number
  ) => CatalogProductVariantAttributeList | undefined;
  appVariantIcon: (variantHandle: string) => string | undefined;
  calculatePlanId: (productId: number, planFamilyId: number) => number;
  calculateFrequencyDays: (frequencyId: number) => number;
}

export const useStoreCatalogEffect = (): StoreCatalogEffect => {
  const { data, error, isLoading } = useSWR('appCatalog', appCatalog.fetch, {
    revalidateOnReconnect: true,
    refreshInterval: 60000,
  });

  const getRegionalPlans = () => appCatalogRegionalPlans(data);

  const getRegionalTrial = (
    trialId: 1 | 2 | 3 | 4,
    planId: 15 | 16 | undefined
  ) => appCatalogRegionalTrial(data, trialId, planId);

  const getRegionalProduct = (productId: number) =>
    appCatalogRegionalProduct(data, productId);

  const getRegionalProductVariant = (variantId: number) =>
    appCatalogRegionalProductVariant(data, variantId);

  const getPowerVariants = (power: string) =>
    appCatalogPowerVariants(data, power);

  const getTrialVariant = (
    variantId: number,
    productType?: 'daily' | 'vitamin'
  ) => appCatalogTrialVariant(data, variantId, productType);

  const getOTPVariant = (
    variantId: number,
    productType?: 'daily' | 'vitamin'
  ) => appCatalogOTPVariant(data, variantId, productType);

  const getVariantDetails = (variantId: number) =>
    appCatalogVariantDetails(data, variantId);

  const appVariantIcon = (variantHandle: string) =>
    appCatalogVariantIcon(variantHandle);
  const calculatePlanId = (productId: number, planFamilyId: number): number => {
    if (productId === 1) {
      if (planFamilyId === 1) {
        return 13;
      } else if (planFamilyId === 2) {
        return 15;
      } else if (planFamilyId === 3) {
        return 17;
      }
    }

    if (productId === 2) {
      if (planFamilyId === 1) {
        return 14;
      } else if (planFamilyId === 2) {
        return 16;
      } else if (planFamilyId === 3) {
        return 18;
      }
    }

    return 0;
  };

  const calculateFrequencyDays = (frequencyId: number): number => {
    switch (frequencyId) {
      case 12:
        return 504;
      case 11:
        return 420;
      case 10:
        return 336;
      case 9:
        return 280;
      case 8:
        return 224;
      case 7:
        return 168;
      case 6:
        return 140;
      case 5:
        return 112;
      case 4:
        return 84;
      case 3:
        return 56;
      case 2:
        return 42;
      case 1:
      default:
        return 28;
    }
  };

  return {
    catalog: data as AppCatalog | undefined,
    loading: isLoading,
    error,
    getRegionalPlans,
    getRegionalTrial,
    getRegionalProduct,
    getRegionalProductVariant,
    getPowerVariants,
    getTrialVariant,
    getOTPVariant,
    getVariantDetails,
    appVariantIcon,
    calculatePlanId,
    calculateFrequencyDays,
  };
};
