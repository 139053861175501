import { CustomerPaymentGateway } from '@hiwaldo/sdk';
import { Customer } from '@hiwaldo/sdk/interfaces';
import { CustomerDataState } from '../../redux';

export const customerParser = {
  fromCustomerUpdate: async (
    state: CustomerDataState,
    customer: Customer
  ): Promise<CustomerDataState> => {
    const updatedState: CustomerDataState = { ...state };

    updatedState.customer = customer;
    updatedState.authedId = customer.id;

    updatedState.customBilling = !customer.matchAddress;
    updatedState.includesShippingBilling = !!customer.shippingAddress;
    updatedState.includesPaymentDetails = !!customer.defaultPaymentMethod;
    updatedState.includesSubscription = !!customer.activeCustomerSubscription;
    updatedState.includesPrescription = !!customer.activePrescription;

    updatedState.includesBothEyes = false;
    updatedState.includesDuplicateEyes = false;

    if (customer.activePrescription) {
      updatedState.includesBothEyes = !!(
        customer.activePrescription.leftEye?.productVariantId &&
        customer.activePrescription.rightEye?.productVariantId
      );

      updatedState.includesDuplicateEyes = !!(
        customer.activePrescription.leftEye?.productVariantId &&
        customer.activePrescription.leftEye.productVariantId ===
          customer.activePrescription.rightEye?.productVariantId
      );
    }

    updatedState.clientSecret = undefined;
    updatedState.paypal = undefined;

    if (customer.defaultPaymentMethod) {
      switch (customer.defaultPaymentMethod.gateway) {
        case CustomerPaymentGateway.PAYPAL: {
          updatedState.paypal = undefined;
          break;
        }
        default: {
          updatedState.clientSecret = undefined;
        }
      }
    }

    return updatedState;
  },
};
