import axios from 'axios';
import * as environment from '../../../environment';
import { storedAuthToken, storedSSEToken } from '../../utils';
import {
  AppStorageKey,
  appStorage,
  currentEnvironment,
  currentRegion,
} from '../../../helpers';

export interface SSEAuthResponse {
  response: { sseToken: string } | null;
  error: string | null;
}

export const sseAuthAxios = async (): Promise<SSEAuthResponse> => {
  const savedSSEToken = storedSSEToken();

  if (savedSSEToken) {
    return {
      response: { sseToken: savedSSEToken },
      error: null,
    };
  }

  const region = currentRegion();
  const application = currentEnvironment();

  const externalGateway = environment.envApolloUrls[application].external;

  let error: string | null = null;
  let response = null;

  const token = storedAuthToken() as string;

  try {
    const sseAuthResponse = (await axios.get(externalGateway + '/sse/auth', {
      headers: {
        'x-waldo-region': region,
        'x-browser-auth': token,
      },
    })) as any;

    response = sseAuthResponse.data.data as { sseToken: string };

    appStorage.set(AppStorageKey.SSE_TOKEN, response.sseToken);
  } catch (errorResponse) {
    error = 'Error request SSE authentication';
  }

  return {
    response,
    error,
  };
};
