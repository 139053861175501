import { useState } from 'react';
import { FormInput } from '../../..';
import { FormInputArgs, FormInputValidationRules } from '../../../interfaces';
import { FormInputChangeEvent, FormInputValue } from '../../../types';
import {
  CheckboxInputClickEvent,
  RadioInputClickEvent,
} from '../../../../components';

export function useFormInputEffect(args: FormInputArgs): FormInput {
  const [value, setValue] = useState<FormInputValue>(
    args.initialValue as FormInputValue
  );
  const [rules, setRules] = useState<FormInputValidationRules>(
    args.rules as FormInputValidationRules
  );
  const [errors, setErrors] = useState<string[]>(
    args.initialErrors || ([] as string[])
  );
  const [isValid, setValid] = useState<boolean>(!!args.initialValid);
  const [isTouched, setTouched] = useState<boolean>(!!args.initialTouched);
  const [isBlurred, setBlurred] = useState<boolean>(!!args.initialBlurred);

  const updateValue = (event: FormInputChangeEvent) => {
    switch (args.type) {
      case 'radio': {
        if (typeof value === 'boolean') {
          setValue(!!event.detail.value);
          setTouched(true);
        } else if (args.multiSelect) {
          const currentValue = value !== null ? (value as number[]) : [];
          const radioValue = (event as RadioInputClickEvent).detail
            .index as number;
          const valueIndex = currentValue.indexOf(radioValue);

          if (valueIndex > -1) {
            currentValue.splice(valueIndex, 1);
          } else {
            currentValue.push(radioValue);
          }

          setValue(currentValue);
          setTouched(true);
        } else {
          setValue([(event as RadioInputClickEvent).detail.index as number]);
          setTouched(true);
        }
        break;
      }
      case 'checkbox': {
        if (typeof value === 'boolean') {
          setValue(!!event.detail.value);
          setTouched(true);
        } else {
          const currentValue = value !== null ? (value as number[]) : [];
          const checkboxValue = (event as CheckboxInputClickEvent).detail
            .index as number;
          const valueIndex = currentValue.indexOf(checkboxValue);

          if (valueIndex > -1) {
            currentValue.splice(valueIndex, 1);
          } else {
            currentValue.push(checkboxValue);
          }

          setValue(currentValue);
          setTouched(true);
        }
        break;
      }
      default: {
        setValue(event.detail.value);
        setTouched(true);
      }
    }
  };

  const updateErrors = (valid: boolean, errors: string[]) => {
    setValid(valid);
    setErrors(errors);
  };

  const doMatch = (input: FormInput) => {
    setValue(input.value);
    setErrors(input.errors);
    setValid(input.isValid);
    setTouched(false);
    setBlurred(true);
  };

  const doReset = (
    wipe = false,
    valid: boolean | undefined = undefined,
    newValue: FormInputValue | undefined = undefined
  ) => {
    const cleanValue: FormInputValue = newValue
      ? newValue
      : !wipe
      ? (args.initialValue as FormInputValue)
      : typeof args.initialValue === 'boolean'
      ? false
      : Array.isArray(args.initialValue)
      ? []
      : '';

    setValue(cleanValue);
    setRules(args.rules);
    setErrors([]);
    setValid(
      typeof valid !== 'undefined'
        ? valid
        : args.rules.required
        ? false
        : !!args.initialValid
    );
    setTouched(false);
    setBlurred(true);
  };

  return {
    type: args.type,
    id: args.id,
    name: args.name,
    validators: args.validators,
    choices: args.choices,
    phrase: args.phrase,
    isBlurred,
    isTouched,
    isValid,
    errors,
    rules,
    value,
    setBlurred,
    setTouched,
    setValid,
    setRules,
    setValue,
    updateValue,
    updateErrors,
    doMatch,
    doReset,
  };
}
