import { appStorage, AppStorageKey } from '../../../helpers';
import jwt from 'jsonwebtoken';

export function storedSSEToken(validate?: boolean) {
  let token: string | null = appStorage.get(AppStorageKey.SSE_TOKEN) || null;

  if (token && validate) {
    const date = new Date();
    const jwtDecoded = jwt.decode(token, { complete: true });

    if (
      !jwtDecoded ||
      typeof jwtDecoded.payload === 'string' ||
      (jwtDecoded.payload.exp &&
        jwtDecoded.payload.exp * 1000 < date.getTime() - 864000000)
    ) {
      appStorage.remove(AppStorageKey.SSE_TOKEN);
      token = null;
    }
  }

  return token || undefined;
}
