import { Cart } from '@hiwaldo/sdk/interfaces';
import { ApolloClient, ApolloQueryResult } from 'apollo-boost';
import { FIND_CART_BY_ID_GQL } from '../../graphql';

export const ordersFindCartByIdQuery = async (
  apolloClient: ApolloClient<any>,
  id: number
): Promise<
  ApolloQueryResult<{
    findCartById: Cart;
  }>
> => {
  return apolloClient.query({
    query: FIND_CART_BY_ID_GQL,
    variables: {
      id: `${id}`,
    },
    fetchPolicy: 'no-cache',
  });
};
