import { CatalogPlan, CatalogPlanLocalePrice } from '@hiwaldo/sdk/interfaces';
import { currentRegion } from '../current-region';
import { AppCatalog } from './app-catalog.helper';

export interface StoreCatalogRegionalPlan {
  plan: CatalogPlan;
  localePrice: CatalogPlanLocalePrice | undefined;
}

export interface StoreCatalogRegionalPlans {
  monthlyOriginal: StoreCatalogRegionalPlan;
  monthlyVitamin: StoreCatalogRegionalPlan;
  quarterlyOriginal: StoreCatalogRegionalPlan;
  quarterlyVitamin: StoreCatalogRegionalPlan;
  biannualOriginal: StoreCatalogRegionalPlan;
  biannualVitamin: StoreCatalogRegionalPlan;
}

export const appCatalogRegionalPlans = (
  data: AppCatalog | undefined
): StoreCatalogRegionalPlans | undefined => {
  const region = currentRegion();

  if (data) {
    const monthlyOriginal = data.planHydraBoostMonthly;
    const monthlyVitamin = data.planVitaminBoostMonthly;
    const quarterlyOriginal = data.planHydraBoostQuarterly;
    const quarterlyVitamin = data.planVitaminBoostQuarterly;
    const biannualOriginal = data.planHydraBoostBiannual;
    const biannualVitamin = data.planVitaminBoostBiannual;

    return {
      monthlyOriginal: {
        plan: monthlyOriginal,
        localePrice: monthlyOriginal.locales.find(
          (locale) => locale.region === region
        ),
      },
      monthlyVitamin: {
        plan: monthlyVitamin,
        localePrice: monthlyVitamin.locales.find(
          (locale) => locale.region === region
        ),
      },
      quarterlyOriginal: {
        plan: quarterlyOriginal,
        localePrice: quarterlyOriginal.locales.find(
          (locale) => locale.region === region
        ),
      },
      quarterlyVitamin: {
        plan: quarterlyVitamin,
        localePrice: quarterlyVitamin.locales.find(
          (locale) => locale.region === region
        ),
      },
      biannualOriginal: {
        plan: biannualOriginal,
        localePrice: biannualOriginal.locales.find(
          (locale) => locale.region === region
        ),
      },
      biannualVitamin: {
        plan: biannualVitamin,
        localePrice: biannualVitamin.locales.find(
          (locale) => locale.region === region
        ),
      },
    };
  }

  return undefined;
};
