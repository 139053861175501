export enum ApplicationEnvironment {
  PROD = 'prod',
  UAT = 'uat',
  STAGING = 'staging',
  LOCAL = 'local',
}

export const currentEnvironment = (): ApplicationEnvironment =>
  (process.env.APP_ENV as ApplicationEnvironment) ||
  (process.env.NEXT_PUBLIC_APP_ENV as ApplicationEnvironment) ||
  ApplicationEnvironment.LOCAL;
