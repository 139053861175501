import { Customer } from '@hiwaldo/sdk/interfaces';
import { ApolloClient, ApolloQueryResult } from 'apollo-boost';
import { AUTHED_CUSTOMER_GQL } from '../../graphql';

export const customersAuthedCustomerQuery = async (
  apolloClient: ApolloClient<any>
): Promise<
  ApolloQueryResult<{
    authedCustomer: Customer;
  }>
> => {
  return apolloClient.query({
    query: AUTHED_CUSTOMER_GQL,
    fetchPolicy: 'no-cache',
  });
};
