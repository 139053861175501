import { useCallback, useEffect, useState } from 'react';

export const useResponsiveQuery = (width: number) => {
  const [mounted, setMounted] = useState(false);
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: any) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    if (!mounted) setMounted(true);

    if (mounted) {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('resize', updateTarget);

      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('resize', updateTarget);
    }
  }, [mounted]);

  return targetReached;
};
