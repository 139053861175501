import { gql } from 'apollo-boost';

export const LIST_CUSTOMER_ORDERS_GQL = gql`
  query doListCustomerOrders($customerId: ID!) {
    listCustomerOrders(customerId: $customerId) {
      rows
      data {
        id
        updatedAt
        createdAt
        region
        name
        status
        discountCode
        shippingPrice
        discountDeductions
        creditDeductions
        taxPrice
        subTotalPrice
        grandTotalPrice
        trackingId
        trackingUrl
        trackingCarrier
        tags
        charges {
          currency
          status
          refunds {
            value
          }
        }
        renewalOrder {
          id
        }
        cart {
          id
        }
        productVariants {
          productVariantId
          name
          price
          quantity
          sku
        }
        bundles {
          bundleId
          name
          price
          quantity
          sku
          productVariants {
            productVariantId
            name
            price
            quantity
            sku
          }
        }
      }
    }
  }
`;
