export const TrialHydraBoostBundleData = {
  id: 2,
  title: 'Daily Eyecare Duo',
  handle: 'eyecare-starter-kit',
  image: 'eyecare-starter-kit.png',
  plans: [
    {
      plan: {
        id: 15,
        planFamily: {
          title: 'Quarterly',
          handle: 'quarterly',
        },
        title: 'Hydra Boost',
        handle: 'hydra-boost-x-3',
        products: [
          {
            locales: [
              {
                quantity: 3,
                basePrice: 2100,
                actualPrice: 2000,
              },
              {
                quantity: 3,
                basePrice: 1250,
                actualPrice: 1167,
              },
              {
                quantity: 3,
                basePrice: 16000,
                actualPrice: 15667,
              },
              {
                quantity: 3,
                basePrice: 1700,
                actualPrice: 1567,
              },
            ],
            product: {
              title: 'Hydra Boost Daily Lenses',
              handle: 'daily-lenses',
              description:
                'Designed for optimum comfort and moisture. Each lens is made from ultra-breathable Etafilcon A with a high water content of 58%.',
              primaryVariant: {
                assets: [
                  {
                    asset: {
                      path: '/images/products/original-lens/sleeves-Lens2.png',
                    },
                  },
                ],
              },
            },
          },
        ],
        locales: [
          {
            basePrice: 11700,
            actualPrice: 12000,
            sku: 'quarterly-hydra-boost',
            region: 'US',
          },
          {
            basePrice: 7200,
            actualPrice: 7000,
            sku: 'quarterly-hydra-boost',
            region: 'UK',
          },
          {
            basePrice: 87000,
            actualPrice: 88000,
            sku: 'quarterly-hydra-boost',
            region: 'SE',
          },
          {
            basePrice: 9600,
            actualPrice: 9400,
            sku: 'quarterly-hydra-boost',
            region: 'EU',
          },
        ],
      },
    },
  ],
  products: [],
  bundles: [
    {
      quantity: 1,
      bundle: {
        title: 'Daily Eyecare Duo',
        handle: 'hydra-boost-trial-bundle',
        products: [
          {
            quantity: 1,
            product: {
              title: 'Hydra Boost Daily Lenses',
              handle: 'trial-daily-lenses',
              description:
                'Designed for optimum comfort and moisture. Each lens is made from ultra-breathable Etafilcon A with a high water content of 58%.',
              primaryVariant: {
                title: 'Lens Trial',
                handle: 'trial-daily-lenses-negative-0-50',
                assets: [
                  {
                    asset: {
                      path: '/images/products/original-lens/sleeves-Lens2.png',
                    },
                  },
                ],
              },
            },
          },
          {
            quantity: 1,
            product: {
              title: 'Hydration Drops',
              handle: 'hydration-drops',
              description:
                'Maximum hydration for your hard-working eyes, formulated with 100% natural active ingredients that support your eye’s own ability to heal itself.',
              primaryVariant: {
                title: 'Hydration Drops',
                handle: 'hydration-drops',
                assets: [
                  {
                    asset: {
                      path: '/images/products/drops/hydration-drops-white-bg.png',
                    },
                  },
                ],
              },
            },
          },
          {
            quantity: 1,
            product: {
              title: 'Waldo Tote Bag',
              handle: 'tote-bag',
              description: 'Waldo Tote Bag.',
              primaryVariant: {
                title: 'Waldo Tote Bag',
                handle: 'tote-bag',
                assets: [],
              },
            },
          },
        ],
      },
    },
  ],
  locales: [
    {
      leadTime: 12,
      sku: 'hydra-boost-trial-bundle',
      region: 'US',
      pricing: [
        {
          actualPrice: 700,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'hydra-boost-trial-bundle',
      region: 'UK',
      pricing: [
        {
          actualPrice: 700,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'hydra-boost-trial-bundle',
      region: 'SE',
      pricing: [
        {
          actualPrice: 7000,
          basePrice: 0,
        },
      ],
    },
    {
      leadTime: 12,
      sku: 'hydra-boost-trial-bundle',
      region: 'EU',
      pricing: [
        {
          actualPrice: 700,
          basePrice: 0,
        },
      ],
    },
  ],
};
