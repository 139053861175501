import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { appStorage } from '../../../../helpers';
import { stopEventSource } from '../../../../server';
import { legacyVersionBridge } from '../../../utils';
import {
  customerDataSlice,
  cleanCustomerDataState,
  checkoutDataSlice,
  cleanCheckoutDataState,
  cleanBasketDataState,
  basketDataSlice,
  menuPageSlice,
  cleanMenuPageState,
} from '../../../redux';

export interface CustomerAuthLogoutDataEffectReturn {
  enact: () => void;
  clear: () => void;
  loading: boolean;
  success: boolean | undefined;
}

export const useCustomerAuthLogoutEffect =
  (): CustomerAuthLogoutDataEffectReturn => {
    const dispatch = useDispatch();

    const [success, setSuccess] = useState<boolean | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const customerAuthLogout = () => {
      setLoading(true);
      appStorage.clean();
      legacyVersionBridge.customer.logout(dispatch);
      dispatch(basketDataSlice.actions.update(cleanBasketDataState));
      dispatch(checkoutDataSlice.actions.update(cleanCheckoutDataState));
      dispatch(menuPageSlice.actions.update(cleanMenuPageState));
      dispatch(customerDataSlice.actions.update(cleanCustomerDataState));
      stopEventSource();
    };

    const clear = () => {
      setSuccess(undefined);
      setLoading(false);
    };

    return {
      enact: customerAuthLogout,
      clear,
      loading,
      success,
    };
  };
