export const envStripeKeys = {
  prod: {
    US: 'pk_live_IkVkvqrzlGnWn1QWhtMq8Fe3',
    UK: 'pk_live_eB3HbsaHqmtdbNMRpzATRaJV',
    EU: 'pk_live_eB3HbsaHqmtdbNMRpzATRaJV',
    SE: 'pk_live_eB3HbsaHqmtdbNMRpzATRaJV',
  },
  uat: {
    US: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    UK: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    EU: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    SE: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
  },
  staging: {
    US: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    UK: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    EU: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    SE: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
  },
  local: {
    US: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    UK: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    EU: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
    SE: 'pk_test_8S0RgBMnoXRZ4WSsmSevywN0',
  },
};
