import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ASSIGN_CART_CUSTOMER_GQL } from '../../../../graphql';
import { Cart } from '@hiwaldo/sdk/interfaces';

export interface AssignCartCustomerInput {
  cartId: number;
  customerId: number;
}

export interface AssignCartCustomerEffectArgs {
  onComplete?: (cart: Cart) => void;
  onError?: (error: string) => void;
}

export interface AssignCartCustomerEffectReturn {
  enact: (data: AssignCartCustomerInput) => void;
  clear: () => void;
  data: Cart | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
}

export const useAssignCartCustomerEffect = (
  args?: AssignCartCustomerEffectArgs
): AssignCartCustomerEffectReturn => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Cart | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [doAssignCartCustomer, { loading }] = useMutation(
    ASSIGN_CART_CUSTOMER_GQL,
    {
      onCompleted: (data: { assignCartCustomer: Cart }) => {
        if (data.assignCartCustomer) {
          setData(data.assignCartCustomer);
          setSuccess(true);

          if (args?.onComplete) {
            args.onComplete(data.assignCartCustomer);
          }
        }
      },
      onError: (errors) => {
        setSuccess(false);
        setErrorMessage(errors.message);

        if (args?.onError) {
          args.onError(errors.message);
        }
      },
    }
  );

  const assignCartCustomer = (data: AssignCartCustomerInput): void => {
    doAssignCartCustomer({ variables: { ...data } });
  };

  const clear = (): void => {
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: assignCartCustomer,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
