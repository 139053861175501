import { useEffect, useState } from 'react';
import { Region } from '@hiwaldo/sdk';
import * as environment from '../../../environment';
import { useResponsiveQuery } from '../../../utils';

enum PageEnvironmentDisplaySize {
  DESKTOP = 768,
}

export type PageEnvironmentDisplayType = 'mobile' | 'desktop';

export type PageEnvironmentServiceKey =
  | 'apollo-gateway'
  | 'apollo-external'
  | 'loqate'
  | 'domain'
  | 'hostname'
  | 'gtm'
  | 'optimize'
  | 'ipify'
  | 'stripe'
  | 'paypal'
  | 'yotpo-key'
  | 'yotpo-api';

export type PageEnvironmentApplication = 'prod' | 'uat' | 'staging' | 'local';

export interface PageEnvironmentEffect {
  getEnvironment: (
    field: PageEnvironmentServiceKey,
    store: Region | 'DOCTOR'
  ) => string;
  changeRegion: (store: Region, page?: string) => void;
  application: PageEnvironmentApplication;
  region: Region;
  display: PageEnvironmentDisplayType;
  debug: boolean;
}

export const usePageEnvironmentEffect = (): PageEnvironmentEffect => {
  const mobile = useResponsiveQuery(768);

  const region: Region =
    (process.env.REGION as Region) ||
    (process.env.NEXT_PUBLIC_REGION as Region);

  const application: PageEnvironmentApplication =
    (process.env.APP_ENV as PageEnvironmentApplication) ||
    (process.env.NEXT_PUBLIC_APP_ENV as PageEnvironmentApplication);

  const [mounted, setMounted] = useState<boolean>(false);
  const [initialised, setInitialised] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [display, setDisplay] = useState<PageEnvironmentDisplayType>('desktop');

  const getEnvironment = (
    field: PageEnvironmentServiceKey,
    store: Region | 'DOCTOR'
  ): string => {
    switch (field) {
      case 'apollo-gateway':
        return environment.envApolloUrls[application].gateway;
      case 'apollo-external':
        return environment.envApolloUrls[application].external;
      case 'loqate':
        return environment.envLoqateKeys[application].key;
      case 'domain':
        return environment.envHostnames.domain[application][store];
      case 'hostname':
        return environment.envHostnames.host[application][store];
      case 'gtm':
        return environment.envGTMKeys[application] &&
          environment.envGTMKeys[application][store as Region]
          ? environment.envGTMKeys[application][store as Region]
          : '';
      case 'stripe':
        return environment.envStripeKeys[application] &&
          environment.envStripeKeys[application][store as Region]
          ? environment.envStripeKeys[application][store as Region]
          : '';
      case 'paypal':
        return environment.envPayPalUrls[application] &&
          environment.envPayPalUrls[application][store as Region]
          ? environment.envPayPalUrls[application][store as Region]
          : '';
      case 'yotpo-key':
        return environment.envYotpoKeys.key[application] &&
          environment.envYotpoKeys.key[application][store as Region]
          ? environment.envYotpoKeys.key[application][store as Region]
          : '';
      case 'yotpo-api':
        return environment.envYotpoKeys.api[application] &&
          environment.envYotpoKeys.api[application][store as Region]
          ? environment.envYotpoKeys.api[application][store as Region]
          : '';
      default:
        throw Error(`Invalid environment field key: ${field}`);
    }
  };

  const changeRegion = (store: Region, page = ''): void => {
    let location;

    let US = 'www.hiwaldo.com';
    let UK = 'uk.hiwaldo.com';
    let EU = 'eu.hiwaldo.com';
    let SE = 'se.hiwaldo.com';

    if (environment.envHostnames.domain[application]) {
      US = environment.envHostnames.domain[application].US;
      UK = environment.envHostnames.domain[application].UK;
      EU = environment.envHostnames.domain[application].EU;
      SE = environment.envHostnames.domain[application].SE;
    }

    switch (store) {
      case Region.UK:
        location = UK;
        break;
      case Region.SE:
        location = SE;
        break;
      case Region.EU:
        location = EU;
        break;
      case Region.US:
      default:
        location = US;
        break;
    }

    setRedirect(`${location}${page}`);
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }

    if (mounted) {
      const handleResize = () => {
        const desktop = window.matchMedia(
          `(max-width: ${PageEnvironmentDisplaySize.DESKTOP}px)`
        );

        if (desktop.matches) {
          setDisplay('mobile');
        } else {
          setDisplay('desktop');
        }
      };

      if (typeof redirect !== 'undefined') {
        setRedirect(undefined);
        window.location.href = redirect;
      }

      window.addEventListener('resize', handleResize);

      if (!initialised) {
        setInitialised(true);
        handleResize();
      }

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [redirect, mounted, initialised, mobile]);

  return {
    getEnvironment,
    changeRegion,
    application,
    region,
    display,
    debug: application !== 'prod',
  };
};
