import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { Cart, Customer } from '@hiwaldo/sdk/interfaces';
import {
  cartSlice,
  checkoutSlice,
  customerAccountSlice,
  customerSlice,
  discountSlice,
  funnelSlice,
} from '../../redux';

export const legacyVersionBridge = {
  customer: {
    authed: async (dispatch: Dispatch<AnyAction>, data: Customer) => {
      dispatch(customerSlice.actions.authed(data));

      dispatch(
        customerAccountSlice.actions.loadedAccount({
          personal: true,
          delivery: true,
          billing: true,
          payment: true,
          orders: true,
          subscription: {
            plan: true,
            renewal: true,
            prescription: true,
            cost: true,
          },
        })
      );
    },
    registered: async (dispatch: Dispatch<AnyAction>, data: Customer) => {
      dispatch(customerSlice.actions.registered(data));

      dispatch(
        customerAccountSlice.actions.loadedAccount({
          personal: true,
          delivery: true,
          billing: true,
          payment: true,
          orders: true,
          subscription: {
            plan: true,
            renewal: true,
            prescription: true,
            cost: true,
          },
        })
      );
    },
    logout: async (dispatch: Dispatch<AnyAction>) => {
      dispatch(cartSlice.actions.clearCart());
      dispatch(checkoutSlice.actions.clearCheckout());
      dispatch(funnelSlice.actions.clear());
      dispatch(customerSlice.actions.sse(false));
      dispatch(customerSlice.actions.logout());
      dispatch(
        customerAccountSlice.actions.loadedAccount({
          personal: true,
          delivery: true,
          billing: true,
          payment: true,
          orders: true,
          subscription: {
            plan: true,
            renewal: true,
            prescription: true,
            cost: true,
          },
        })
      );
    },
  },
  cart: {
    hideBasket: (dispatch: Dispatch<AnyAction>, open: boolean) => {
      if (open) {
        dispatch(cartSlice.actions.showBasket());
      } else {
        dispatch(cartSlice.actions.hideBasket());
      }
    },
    update: (dispatch: Dispatch<AnyAction>, cart: Cart) => {
      dispatch(cartSlice.actions.updateCart(cart));
    },
    clear: (dispatch: Dispatch<AnyAction>) => {
      dispatch(cartSlice.actions.clearCart());
    },
  },
  discount: {
    clear: (dispatch: Dispatch<AnyAction>) => {
      dispatch(discountSlice.actions.clear());
    },
  },
};
