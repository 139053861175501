import { Region } from '@hiwaldo/sdk';
import { OrderCreated } from '@hiwaldo/sdk/event/events/order';
import { currencyLabel } from '../../../utils';
import { GTMItemsInterface } from '../../interfaces';
import {
  AppCatalog,
  appCatalogOTPVariant,
  appCatalogTrialVariant,
} from '../../../helpers';
import {
  TrialHydraBoostBundleData,
  TrialVitaminBoostBundleData,
} from '../../../data';

interface CalculateGtmOrderItemsData {
  items: GTMItemsInterface[];
  amount: number;
  currency: string;
}

export const calculateGtmOrderItems = (
  data: AppCatalog | undefined,
  event: OrderCreated
): CalculateGtmOrderItemsData => {
  const order = event.data.order;
  const amount = order.total_price / 100;
  const currency = currencyLabel(event.data.region as unknown as Region, true);
  const items: GTMItemsInterface[] = [] as any;

  if (order.bundles?.length) {
    let bundlePrice = 0;

    if (order.bundles[0].name.includes('Hydra Boost')) {
      for (const x in TrialHydraBoostBundleData.locales) {
        if (TrialHydraBoostBundleData.locales[x].region === event.data.region) {
          bundlePrice =
            TrialHydraBoostBundleData.locales[x].pricing[0].actualPrice / 100;
        }
      }
    } else if (order.bundles[0].name.includes('Vitamin')) {
      for (const x in TrialVitaminBoostBundleData.locales) {
        if (
          TrialVitaminBoostBundleData.locales[x].region === event.data.region
        ) {
          bundlePrice =
            TrialVitaminBoostBundleData.locales[x].pricing[0].actualPrice / 100;
        }
      }
    }

    items.push({
      item_id: order.bundles[0].id.toString(),
      item_name: order.bundles[0].name,
      affiliation: 'Waldo Store',
      item_brand: 'Waldo',
      item_variant: order.bundles[0].name.toLocaleLowerCase(),
      price: bundlePrice,
    });
  }

  if (order.variants) {
    const trialVariant = order.variants.find((variant) => {
      return variant.name.includes('Trial') && !order.bundles?.length;
    });

    const trialVariantSku = trialVariant
      ? appCatalogTrialVariant(data, trialVariant.id)?.locales.find(
          (locale) => locale.region === event.data.region
        )?.sku
      : '';

    if (trialVariant) {
      items.push({
        item_id: trialVariant.id.toString(),
        item_name: trialVariant.name,
        affiliation: 'Waldo Store',
        item_brand: 'Waldo',
        item_variant: trialVariant.name.toLocaleLowerCase(),
        sku: trialVariantSku,
        price: trialVariant.price ? trialVariant.price! / 100 : 0,
      });
    }

    for (let i = 0; i < order.variants.length; i++) {
      let lensesSku = '';
      if (order.variants[i].name.includes('lens')) {
        lensesSku =
          appCatalogOTPVariant(data, order.variants[i].id)?.locales.find(
            (locale) => locale.region === event.data.region
          )?.sku || '';
      }

      if (order.variants[i].price && order.variants[i].price! > 0) {
        items.push({
          item_id: order.variants[i].id.toString(),
          item_name: order.variants[i].name,
          affiliation: 'Waldo Store',
          item_brand: 'Waldo',
          item_variant: order.variants[i].name.toLocaleLowerCase(),
          sku: lensesSku,
          quantity: order.variants[i].quantity,
          price: order.variants[i].price ? order.variants[i].price! / 100 : 0,
        });
      }
    }
  }

  return { items, amount, currency };
};
