import { RoutingEffect } from '../routing';
import { useState } from 'react';
import { Region } from '@hiwaldo/sdk';
import { PageEnvironmentDisplayType } from '../environment';
import { useSelector } from 'react-redux';
import {
  ButtonInputProps,
  DropdownProps,
  DropdownSlot,
  FooterProps,
  FooterTemplate,
  TextInputProps,
  TextInputValidator,
} from '../../../components';
import {
  StoreState,
  useCaptureNewsletterEffect,
  useCaptureNewsletterFormSetEffect,
  usePagePolicyEffect,
} from '../../../state';

export const usePageFooterEffect = (
  template: FooterTemplate,
  routing: RoutingEffect,
  display: PageEnvironmentDisplayType,
  region: Region
): FooterProps => {
  const { pageNewsletterState } = useSelector((state: StoreState) => {
    return {
      pageNewsletterState: state.page.newsletter,
    };
  });

  const [isRegionOpen, setIsRegionOpen] = useState(false);

  const [validateEmailOnBlur, setValidateEmailOnBlur] = useState<boolean>(true);
  const [hideEmailValidation, setHideEmailValidation] = useState<boolean>(true);

  const pagePolicies = usePagePolicyEffect();

  let label: string;
  let slots: DropdownSlot[] = [];

  const usSlot: DropdownSlot = {
    onClickHandler: routing.regionLinks.us.onLinkHandler,
    label: 'United States',
    image: '/images/icons/store-us.svg',
  };

  const ukSlot: DropdownSlot = {
    onClickHandler: routing.regionLinks.uk.onLinkHandler,
    label: 'United Kingdom',
    image: '/images/icons/store-uk.svg',
  };

  const euSlot: DropdownSlot = {
    onClickHandler: routing.regionLinks.eu.onLinkHandler,
    label: 'Europe',
    image: '/images/icons/store-eu.svg',
  };

  const seSlot: DropdownSlot = {
    onClickHandler: routing.regionLinks.se.onLinkHandler,
    label: 'Sweden',
    image: '/images/icons/store-se.svg',
  };

  switch (region) {
    case Region.UK:
      label = 'United Kingdom';
      slots = [seSlot, euSlot, usSlot];
      break;
    case Region.EU:
      label = 'Europe';
      slots = [seSlot, ukSlot, usSlot];
      break;
    case Region.SE:
      label = 'Sweden';
      slots = [euSlot, ukSlot, usSlot];
      break;
    case Region.US:
    default:
      label = 'United States';
      slots = [seSlot, euSlot, ukSlot];
  }

  const regionDropdown: DropdownProps = {
    id: 'footer-region-dropdown',
    name: 'footer-region-dropdown',
    template: 'dropdownSelect',
    styleClasses: ['dropdownSelect'],
    isOpen: isRegionOpen,
    display,
    label,
    slots,
    onClickHandler: () => {
      setIsRegionOpen(!isRegionOpen);
    },
    onOpenHandler: () => {
      setIsRegionOpen(true);
    },
    onCloseHandler: () => {
      setIsRegionOpen(false);
    },
  };

  const form = useCaptureNewsletterFormSetEffect();

  const captureNewsletter = useCaptureNewsletterEffect();
  const { loading, success } = captureNewsletter;

  const onSubmitHandler = () => {
    if (form.members.email.isValid) {
      form.setSubmitted();

      captureNewsletter.enact(form.members.email.value as string);
    }
  };

  const newsletterInputProps: TextInputProps = {
    type: 'email',
    styleClasses: ['textInputBase', 'textInputNewsletter'],
    placeholder: 'Email',
    validators: form.members.email.validators as TextInputValidator[],
    valid: form.members.email.isValid,
    errors: form.members.email.errors?.length
      ? [form.members.email.errors[0]]
      : [],
    rules: form.members.email.rules,
    formSubmitted: form.isSubmitted,
    validateOnBlur: validateEmailOnBlur,
    hideMessages: hideEmailValidation,
    id: form.members.email.id,
    name: form.members.email.id,
    value: form.members.email.value as string,
    isTouched: form.members.email.isTouched || false,
    isDisabled: loading || success,
    submitOnEnter: true,
    onSubmitHandler,
    onChangeHandler: form.members.email.updateValue,
    onFocusHandler: () => form.members.email.setTouched(true),
    onBlurHandler: () => {
      form.members.email.setBlurred(true);
      setValidateEmailOnBlur(false);
      setHideEmailValidation(false);
    },
    updateErrors: form.members.email.updateErrors,
  };

  const submitInputProps: ButtonInputProps = {
    id: 'checkout-registration-submit',
    name: 'checkout-registration-submit',
    type: 'submit',
    template: 'buttonInputIcon',
    icon: {
      template: 'iconArrowRight',
      styleClasses: ['iconBase'],
    },
    isDisabled: loading || success,
    isLoading: loading,
    styleClasses: ['buttonInputNewsletter'],
    onClickHandler: onSubmitHandler,
  };

  return {
    id: 'footer',
    display,
    template,
    styleClasses: ['footerBase'],
    footerLinks: routing.footerLinks,
    companyLinks: routing.companyLinks,
    socialLinks: routing.socialLinks,
    regionDropdown,
    newsletterInputProps,
    submitInputProps,
    newsletterComplete: pageNewsletterState.isSubscriber,
    cookiePolicyAccepted: pagePolicies.policy.cookies,
    cookiePolicyCloseHandler: () => pagePolicies.setCookiesPolicy(true),
  };
};
