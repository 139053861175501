import { useState } from 'react';
import { klavyioAddListEmailAxios } from '../../../axios';

export type KlavyioAddListEmailEffectArgs = {
  onComplete?: () => void;
  onError?: (error: string) => void;
};

export type KlavyioAddListEmailEffectReturn = {
  enact: (listName: string, email: string) => void;
  clear: () => void;
  data: any | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
};

export const useKlavyioAddListEmailEffect = (
  args?: KlavyioAddListEmailEffectArgs
): KlavyioAddListEmailEffectReturn => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const [data, setData] = useState<any | undefined>(undefined);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const klavyioAddListEmail = (listName: string, email: string) => {
    setLoading(true);

    klavyioAddListEmailAxios(listName, email).then((data) => {
      if (data.response) {
        setData(data.response);
        setSuccess(true);
        setLoading(false);

        if (args?.onComplete) {
          args.onComplete();
        }
      } else {
        const errorMessage = 'Ipify Geolocation: Failed to fetch geolocation';

        setErrorMessage(errorMessage);
        setSuccess(false);
        setLoading(false);

        if (args?.onError) {
          args.onError(errorMessage);
        }
      }
    });
  };

  const clear = (): void => {
    setLoading(false);
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: klavyioAddListEmail,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
